const LOCAL_STORAGE_LOGGED_IN_USER_DATA = "currentUser";
const LOCAL_STORAGE_JWT_TOKEN = "jwtToken";
const APP_NAME = "Zoella";
const API_RESPONSE_MESSAGE_KEY = "message";
const CURRENCY_SYMBOL = "$";

export {
    APP_NAME,
    LOCAL_STORAGE_LOGGED_IN_USER_DATA,
    LOCAL_STORAGE_JWT_TOKEN,
    API_RESPONSE_MESSAGE_KEY,
    CURRENCY_SYMBOL
}
