import {ClientRegistrationStepKeyType} from "../../shared/models/client.model";

export const SET_CLIENT_REGISTRATION_STEP = "SET_CLIENT_REGISTRATION_STEP";
export const SET_CLIENT_REGISTRATION_SUB_STEP = "SET_CLIENT_REGISTRATION_SUB_STEP";

export const setClientRegistrationStep = (step: ClientRegistrationStepKeyType) => {
    return {
        type: SET_CLIENT_REGISTRATION_STEP,
        payload: step
    }
};

export const setClientRegistrationSubStep = (subStep: string) => {
    return {
        type: SET_CLIENT_REGISTRATION_SUB_STEP,
        payload: subStep
    }
};
