import {combineReducers} from "redux";
import AccountReducer, {IAccountReducerState} from "./account.reducer";
import NavigationReducer, {INavigationReducerState} from "./navigation.reducer";
import StaticDataReducer, {IStaticDataReducerState} from "./static-data.reducer";
import UserReducer, {IUserReducerState} from "./user.reducer";
import ClientReducer, {IClientReducerState} from "./client.reducer";
import StateManagementReducer, {IStateManagementReducerState} from "./state-management.reducer";

export interface IRootReducerState {
    account: IAccountReducerState,
    navigation: INavigationReducerState,
    staticData: IStaticDataReducerState,
    client: IClientReducerState,
    user: IUserReducerState,
    stateManagement: IStateManagementReducerState,
}

const rootReducer = combineReducers({
    account: AccountReducer,
    navigation: NavigationReducer,
    staticData: StaticDataReducer,
    user: UserReducer,
    client: ClientReducer,
    stateManagement: StateManagementReducer,
});

export default rootReducer;
