const POSITIVE_INTEGERS_PARTIAL = /^(\d+)?(\.)?(\d+)?$/;
const POSITIVE_INTEGERS = /^\d+(\.\d+)?$/;
const POSITIVE_WHOLE_NUMBERS = /^\d+?$/;

const Patterns = {
    POSITIVE_INTEGERS,
    POSITIVE_INTEGERS_PARTIAL,
    POSITIVE_WHOLE_NUMBERS
}

export default Patterns;



