import ENV from "./ENV";

interface IAPIConfig {
    [k: string]: {
        URL: string | Function | any,
        METHOD: "get" | "post" | "delete" | "put"
    }
}

const APIConfig: IAPIConfig = {
    //meta start
    CONSULTATION_DURATION_LIST: {
        URL: ENV.ADMIN_API_URL + "/consultation/duration",
        METHOD: "get"
    },
    GENDER_LIST: {
        URL: ENV.ADMIN_API_URL + "/gender",
        METHOD: "get"
    },
    LANGUAGE_LIST: {
        URL: ENV.ADMIN_API_URL + "/language",
        METHOD: "get"
    },
    PHONE_TYPE_LIST: {
        URL: ENV.ADMIN_API_URL + "/phone/type",
        METHOD: "get"
    },
    EMPLOYMENT_STATUS_LIST: {
        URL: ENV.ADMIN_API_URL + "/employement/status",
        METHOD: "get"
    },
    RELATIONSHIP_LIST: {
        URL: ENV.ADMIN_API_URL + "/relationship",
        METHOD: "get"
    },
    CPT_CODES_LIST: {
        URL: ENV.ADMIN_API_URL + "/cptCodes",
        METHOD: "get"
    },
    MEDICAL_HISTORY_OPTIONS_LIST: {
        URL: ENV.ADMIN_API_URL + "/medicalHistory/question",
        METHOD: "get"
    },
    SURGICAL_HISTORY_OPTIONS_LIST: {
        URL: ENV.ADMIN_API_URL + "/surgicalHistory/question",
        METHOD: "get"
    },
    MUSCULOSKELETAL_HISTORY_OPTIONS_LIST: {
        URL: ENV.ADMIN_API_URL + "/musculoSkeletal/question",
        METHOD: "get"
    },
    SOCIAL_MEDIA_PLATFORM_LIST: {
        URL: ENV.ADMIN_API_URL + "/socialMedia",
        METHOD: "get"
    },
    REFERRAL_TYPE_LIST: {
        URL: ENV.ADMIN_API_URL + "/referral",
        METHOD: "get"
    },
    COMMUNICATION_MODE_TYPE_LIST: {
        URL: ENV.ADMIN_API_URL + "/communication/type",
        METHOD: "get"
    },
    BODY_PART_LIST: {
        URL: ENV.ADMIN_API_URL + "/bodyParts",
        METHOD: "get"
    },
    INJURY_TYPE_LIST: {
        URL: ENV.ADMIN_API_URL + "/injuryTypes",
        METHOD: "get"
    },
    PROGRESS_REPORT_STATS_LIST: {
        URL: ENV.ADMIN_API_URL + '/progressStats',
        METHOD: 'get'
    },
    CONCUSSION_FILE_TYPES: {
        URL: ENV.ADMIN_API_URL + '/concussionTypes',
        METHOD: 'get'
    },
    MEDICAL_RECORD_DOCUMENT_TYPES: {
        URL: ENV.ADMIN_API_URL + '/documentTypes',
        METHOD: 'get'
    },
    //meta end

    // authentication start
    LOGIN: {
        URL: ENV.CUSTOMER_API_URL + "/login",
        METHOD: "post"
    },
    VERIFY_OTP: {
        URL: ENV.CUSTOMER_API_URL + "/verifyOTP",
        METHOD: "post"
    },
    CHECK_LOGIN: {
        URL: ENV.CUSTOMER_API_URL + "/login",
        METHOD: "get"
    },
    LOGOUT: {
        URL: ENV.CUSTOMER_API_URL + "/login",
        METHOD: "delete"
    },
    // authentication end

    CLIENT_INJURY_DETAILS_SAVE: {
        URL: (clientId: string) => ENV.CUSTOMER_API_URL + '/client/' + clientId + '/injuryDetailsForm',
        METHOD: 'post'
    },
    SAVE_CLIENT_BASIC_DETAILS: {
        URL: ENV.CUSTOMER_API_URL + '/profile',
        METHOD: 'put'
    },
    GET_CLIENT_BASIC_DETAILS: {
        URL: ENV.CUSTOMER_API_URL + '/profile',
        METHOD: 'get'
    },
    SAVE_CLIENT_COMMUNICATION_REFERRAL_DETAILS: {
        URL: ENV.CUSTOMER_API_URL + '/account',
        METHOD: 'put'
    },
    GET_CLIENT_COMMUNICATION_REFERRAL_DETAILS: {
        URL: ENV.CUSTOMER_API_URL + '/account',
        METHOD: 'get'
    },
    GET_CLIENT_MEDICAL_HISTORY_DETAILS: {
        URL: ENV.CUSTOMER_API_URL + '/medicalQuestionnaire',
        METHOD: 'get'
    },
    SAVE_CLIENT_MEDICAL_PROVIDER_INFO_DETAILS: {
        URL: ENV.CUSTOMER_API_URL + '/medicalProvider',
        METHOD: 'post'
    },
    SAVE_CLIENT_MEDICAL_HISTORY_QUESTIONNAIRE_PERSONAL_HABITS_DETAILS: {
        URL: ENV.CUSTOMER_API_URL + '/personalHabits',
        METHOD: 'post'
    },
    SAVE_CLIENT_MEDICAL_HISTORY_QUESTIONNAIRE_MEDICATION_SUPPLEMENTS_DETAILS: {
        URL: ENV.CUSTOMER_API_URL + '/medication',
        METHOD: 'post'
    },
    SAVE_CLIENT_MEDICAL_HISTORY_QUESTIONNAIRE_ALLERGIES_DETAILS: {
        URL: ENV.CUSTOMER_API_URL + '/allergies',
        METHOD: 'post'
    },
    SAVE_CLIENT_MEDICAL_HISTORY_MEDICAL_HISTORY_DETAILS: {
        URL: ENV.CUSTOMER_API_URL + '/medicalHistory',
        METHOD: 'post'
    },
    SAVE_CLIENT_MEDICAL_HISTORY_FEMALE_ONLY_DETAILS: {
        URL: ENV.CUSTOMER_API_URL + '/femaleQuestion',
        METHOD: 'post'
    },
    SAVE_CLIENT_MEDICAL_HISTORY_SURGICAL_HISTORY_DETAILS: {
        URL: ENV.CUSTOMER_API_URL + '/surgicalHistory',
        METHOD: 'post'
    },
    SAVE_CLIENT_MEDICAL_HISTORY_MUSCULOSKELETAL_HISTORY_DETAILS: {
        URL: ENV.CUSTOMER_API_URL + '/musculoskeletalHistory',
        METHOD: 'post'
    },
    SUBMIT_REGISTRATION_CONSENT_FORM: {
        URL: ENV.CUSTOMER_API_URL + '/provideConsent',
        METHOD: 'post'
    },
}

export default APIConfig;
