import React, {useEffect, useLayoutEffect} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import NotFoundScreen from "../screens/not-found/notFoundScreen";
import TestScreen from "../screens/test/TestScreen";
import DesignSystemScreen from "../screens/design-system/DesignSystemScreen";
import ComingSoonScreen from "../screens/coming-soon/ComingSoonScreen";

import {
    CLIENT_INJURY_DETAILS_ROUTE,
    CLIENT_INJURY_SUCCESS_ROUTE,
    CLIENT_REGISTRATION_CONSENT_ROUTE,
    CLIENT_REGISTRATION_ROUTE,
    COMING_SOON_ROUTE,
    DESIGN_SYSTEM_ROUTE,
    LOGIN_ROUTE,
    NOT_FOUND_ROUTE,
    ON_BOARDING_ROUTE,
    TEST_ROUTE
} from "../constants/RoutesConfig";
import ClientInjuryDetailsScreen from "../screens/client-injury-details/ClientInjuryDetailsScreen";
import ClientSuccessScreen from "../screens/client-success/ClientSuccessScreen";
import AppLayout from "../layouts/app-layout/AppLayout";
import LoginScreen from "../screens/account/login/LoginScreen";
import OnboardingScreen from "../screens/account/onboarding/OnboardingScreen";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../store/reducers";
import ClientRegistrationScreen from "../screens/client-registration/ClientRegistrationScreen";
import ClientRegistrationConsentScreen from "../screens/client-registration-consent/ClientRegistrationConsentScreen";

const ProtectedRoute = (props: React.PropsWithChildren<any>) => {

    const {children} = props;
    const {token} = useSelector((state: IRootReducerState) => state.account);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!token) {
            navigate('/login?returnUrl=' + encodeURIComponent(location.pathname + location.search));
        }
    }, [token, navigate, location]);

    return children;
}
//
// const UnProtectedRoute = (props: React.PropsWithChildren<any>) => {
//
//     const {children} = props;
//     const {token} = useSelector((state: IRootReducerState) => state.account);
//     const navigate = useNavigate();
//     const location = useLocation();
//
//     useEffect(() => {
//         let returnUrl = CommonService._routeConfig.DefaultRoute();
//         if (!!token) {
//             const query = CommonService.parseQueryString(location.search);
//             if (Object.keys(query).includes('returnUrl')) {
//                 returnUrl = query.returnUrl;
//             }
//             navigate(returnUrl);
//         }
//     }, [token, navigate, location])
//
//     return children;
// }

export interface NavigatorProps {

}

const Navigator = (props: NavigatorProps) => {

    const location = useLocation();

    useLayoutEffect(() => {
        document.querySelector("body")?.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Routes>
            <Route path={'/'} element={<Navigate to={LOGIN_ROUTE}/>}/>
            <Route path={LOGIN_ROUTE} element={<LoginScreen/>}/>
            <Route element={<AppLayout/>}>
                <Route path={CLIENT_INJURY_DETAILS_ROUTE} element={<ClientInjuryDetailsScreen/>}/>
                <Route path={CLIENT_INJURY_SUCCESS_ROUTE} element={<ClientSuccessScreen/>}/>
                <Route
                    path={ON_BOARDING_ROUTE}
                    element={
                        <ProtectedRoute>
                            <OnboardingScreen/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={CLIENT_REGISTRATION_ROUTE}
                    element={
                        <ProtectedRoute>
                            <ClientRegistrationScreen/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={CLIENT_REGISTRATION_CONSENT_ROUTE}
                    element={
                        <ProtectedRoute>
                            <ClientRegistrationConsentScreen/>
                        </ProtectedRoute>
                    }
                />
            </Route>
            <Route path={COMING_SOON_ROUTE} element={<ComingSoonScreen/>}/>
            <Route path={TEST_ROUTE} element={<TestScreen/>}/>
            <Route path={DESIGN_SYSTEM_ROUTE} element={<DesignSystemScreen/>}/>
            <Route path={NOT_FOUND_ROUTE} element={<NotFoundScreen/>}/>
            <Route path="*" element={<Navigate to={NOT_FOUND_ROUTE}/>}/>
        </Routes>
    )
};

export default Navigator;
