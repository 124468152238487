import "./ClientWorkInformationComponent.scss";
import * as Yup from "yup";
import {IClientWorkDetails} from "../../../shared/models/client.model";
import {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import {ImageConfig, Misc} from "../../../constants";
import FormDebuggerComponent from "../../../shared/components/form-debugger/FormDebuggerComponent";
import FormikInputComponent from "../../../shared/components/form-controls/formik-input/FormikInputComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import FormikSelectDropdownComponent
    from "../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";

interface ClientWorkInformationComponentProps {
    onNext: () => void;
}

const ClientWorkInformationFormValidationSchema = Yup.object({
    work_info: Yup.object({
        occupation: Yup.string()
            .min(1, 'Occupation is required')
            .max(100, 'Occupation cannot be more than 100 chars')
            .required('Occupation is required'),
        employment_status: Yup.string().required('Employment Status is required'),
    }),
});

const ClientWorkInformationFormInitialValues: IClientWorkDetails = {
    work_info: {
        occupation: "",
        employment_status: "",
    }
}

const ClientWorkInformationComponent = (props: ClientWorkInformationComponentProps) => {

    const {onNext} = props;
    const [clientWorkInformationFormInitialValues, setClientWorkInformationFormInitialValues] = useState<IClientWorkDetails>(_.cloneDeep(ClientWorkInformationFormInitialValues));

    const {
        clientBasicDetails,
        isClientBasicDetailsLoaded,
    } = useSelector((state: IRootReducerState) => state.client);

    const {
        employmentStatusList
    } = useSelector((state: IRootReducerState) => state.staticData);

    useEffect(() => {
        if (isClientBasicDetailsLoaded) {
            setClientWorkInformationFormInitialValues(clientBasicDetails);
        }
    }, [clientBasicDetails, isClientBasicDetailsLoaded]);

    const onSubmit = useCallback((values: any, {setSubmitting, setErrors}: FormikHelpers<any>) => {
        setSubmitting(true);
        CommonService._client.ClientBasicDetailsSaveAPICall(values)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setSubmitting(false);
                onNext();
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                setSubmitting(false);
            })
    }, [onNext]);

    return (
        <div className={'client-work-information-component'}>
            <Formik
                validationSchema={ClientWorkInformationFormValidationSchema}
                initialValues={clientWorkInformationFormInitialValues}
                validateOnChange={false}
                validateOnBlur={true}
                enableReinitialize={true}
                validateOnMount={true}
                onSubmit={onSubmit}
            >
                {({values, validateForm, isValid, isSubmitting}) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        validateForm();
                    }, [validateForm, values]);
                    return (
                        <Form className="t-form" noValidate={true}>
                            <FormDebuggerComponent values={values} showDebugger={false}/>
                            <div className="t-form-controls">
                                <div className="ts-row">
                                    <div className="ts-col-lg-6">
                                        <Field name={'work_info.occupation'}>
                                            {
                                                (field: FieldProps) => (
                                                    <FormikInputComponent
                                                        label={'Occupation'}
                                                        placeholder={'Enter Your Occupation'}
                                                        required={true}
                                                        formikField={field}
                                                        fullWidth={true}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div className="ts-col-lg-6">
                                        <Field name={'work_info.employment_status'}>
                                            {
                                                (field: FieldProps) => (
                                                    <FormikSelectDropdownComponent
                                                        id={'employment_status_dd'}
                                                        label={'Employment Status'}
                                                        placeholder={'Select Employment Status'}
                                                        options={employmentStatusList}
                                                        required={true}
                                                        formikField={field}
                                                        fullWidth={true}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className="t-form-actions">
                                <ButtonComponent
                                    id={'next_btn'}
                                    type={"submit"}
                                    suffixIcon={<ImageConfig.ArrowRightCircle/>}
                                    disabled={!isValid || isSubmitting}
                                    isLoading={isSubmitting}
                                >
                                    Next
                                </ButtonComponent>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );

};

export default ClientWorkInformationComponent;
