import "./ClientRegistrationMedicalProviderDetailsComponent.scss";
import * as Yup from "yup";
import {IClientMedicalProviderDetails} from "../../shared/models/client.model";
import {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "../../store/reducers";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {CommonService} from "../../shared/services";
import {IAPIResponseType} from "../../shared/models/api.model";
import {ImageConfig, Misc} from "../../constants";
import FormDebuggerComponent from "../../shared/components/form-debugger/FormDebuggerComponent";
import FormikInputComponent from "../../shared/components/form-controls/formik-input/FormikInputComponent";
import ButtonComponent from "../../shared/components/button/ButtonComponent";
import FormControlLabelComponent from "../../shared/components/form-control-label/FormControlLabelComponent";
import FormikPhoneInputComponent from "../../shared/components/formik-phone-input/FormikPhoneInputComponent";
import IconButtonComponent from "../../shared/components/icon-button/IconButtonComponent";
import ToolTipComponent from "../../shared/components/tool-tip/ToolTipComponent";
import FormikDatePickerComponent
    from "../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent";
import {getClientMedicalHistoryDetails} from "../../store/actions/client.action";

interface ClientRegistrationMedicalProviderDetailsComponentProps {
    onNext: () => void;
}

const ClientMedicalProviderDetailsFormValidationSchema = Yup.object({
    medical_provider_info: Yup.object({
        name: Yup.string().nullable(),
        primary_phone: Yup.string().nullable(),
        last_examination_date: Yup.string().nullable(),
    })
});

const ClientMedicalProviderDetailsFormInitialValues: IClientMedicalProviderDetails = {
    medical_provider_info: {
        name: "",
        primary_phone: "",
        last_examination_date: ""
    }
}

const ClientRegistrationMedicalProviderDetailsComponent = (props: ClientRegistrationMedicalProviderDetailsComponentProps) => {

    const {onNext} = props;
    const dispatch = useDispatch();
    const [clientMedicalProviderDetailsFormInitialValues, setClientMedicalProviderDetailsFormInitialValues] = useState<IClientMedicalProviderDetails>(_.cloneDeep(ClientMedicalProviderDetailsFormInitialValues));

    const {
        clientMedicalHistoryDetails,
        isClientMedicalHistoryDetailsLoaded,
    } = useSelector((state: IRootReducerState) => state.client);


    useEffect(() => {
        if (clientMedicalHistoryDetails) {
            setClientMedicalProviderDetailsFormInitialValues(clientMedicalHistoryDetails);
        }
    }, [clientMedicalHistoryDetails, isClientMedicalHistoryDetailsLoaded]);

    const onSubmit = useCallback((values: any, {setSubmitting, setErrors}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {..._.cloneDeep(values),  is_medical_provider_details_updated: true };
        payload.last_examination_date = CommonService.convertDateFormat(payload.last_examination_date);
        CommonService._client.ClientMedicalProviderInfoDetailsSaveAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setSubmitting(false);
                dispatch(getClientMedicalHistoryDetails());
                onNext();
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                setSubmitting(false);
            })
    }, [dispatch, onNext]);

    return (
        <div
            className={'client-registration-details-block client-registration-medical-provider-details-component'}>
            <div className="client-registration-details-block__header">
                <div className="client-registration-details-block__title">
                    Medical Provider Information
                </div>
            </div>
            <div className="client-registration-details-block__body">
                <Formik
                    validationSchema={ClientMedicalProviderDetailsFormValidationSchema}
                    initialValues={clientMedicalProviderDetailsFormInitialValues}
                    validateOnChange={false}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    validateOnMount={true}
                    onSubmit={onSubmit}
                >
                    {({values, setFieldValue, validateForm, isValid, isSubmitting}) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                            validateForm();
                        }, [validateForm, values]);
                        return (
                            <Form className="t-form" noValidate={true}>
                                <FormDebuggerComponent values={values} showDebugger={false}/>
                                <div className="t-form-controls">
                                    <FormControlLabelComponent label={"Family Doctor"}/>
                                    <div className="ts-row">
                                        <div className="ts-col-md-5">
                                            <Field name={'medical_provider_info.name'}>
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikInputComponent
                                                            id={"full_name_input"}
                                                            label={'Full Name'}
                                                            placeholder={'Enter Full Name'}
                                                            titleCase={true}
                                                            formikField={field}
                                                            fullWidth={true}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-md-5">
                                            <Field name={'medical_provider_info.primary_phone'}>
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikPhoneInputComponent
                                                            id={"phone_input"}
                                                            label={'Primary Phone Number'}
                                                            placeholder={'Enter Primary Phone Number'}
                                                            formikField={field}
                                                            fullWidth={true}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-md-2 d-flex align-items-center">
                                            <IconButtonComponent className={"form-helper-icon"}>
                                                <ToolTipComponent
                                                    showArrow={true}
                                                    position={"left"}
                                                    tooltip={"This phone number will be used to communicate with you in case of emergency. Ensure that this number is constantly operational."}>
                                                    <ImageConfig.InfoIcon/>
                                                </ToolTipComponent>
                                            </IconButtonComponent>
                                        </div>
                                    </div>
                                    <FormControlLabelComponent label={"Date of Last Physical Examination"}/>
                                    <div className="ts-row">
                                        <div className="ts-col-md-5">
                                            <Field name={'medical_provider_info.last_examination_date'}>
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikDatePickerComponent
                                                            id={"dob_input"}
                                                            label={'Date'}
                                                            placeholder={'Select Date of Last Examination'}
                                                            formikField={field}
                                                            fullWidth={true}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                <div className="t-form-actions">
                                    <ButtonComponent
                                        id={"next_btn"}
                                        type={"submit"}
                                        suffixIcon={<ImageConfig.ArrowRightCircle/>}
                                        disabled={!isValid || isSubmitting}
                                        isLoading={isSubmitting}
                                    >
                                        Next
                                    </ButtonComponent>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );


};

export default ClientRegistrationMedicalProviderDetailsComponent;
