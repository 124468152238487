// NON-AUTH ROUTES
const TEST_ROUTE = "/test";
const NOT_FOUND_ROUTE = "/not-found";
const LOGIN_ROUTE = "/login";
const DESIGN_SYSTEM_ROUTE = "/ds";
const COMING_SOON_ROUTE = "/coming-soon";
const CLIENT_INJURY_DETAILS_ROUTE = "/client-injury-information/:appointmentId";
const CLIENT_INJURY_SUCCESS_ROUTE = "/client-injury-success";
const ON_BOARDING_ROUTE = "/on-boarding";
const CLIENT_REGISTRATION_ROUTE = "/registration";
const CLIENT_REGISTRATION_CONSENT_ROUTE = "/registration-consent";

const GUEST_ROUTES = [NOT_FOUND_ROUTE, DESIGN_SYSTEM_ROUTE];

export {
    COMING_SOON_ROUTE,
    NOT_FOUND_ROUTE,
    GUEST_ROUTES,
    TEST_ROUTE,
    DESIGN_SYSTEM_ROUTE,
    CLIENT_INJURY_DETAILS_ROUTE,
    CLIENT_INJURY_SUCCESS_ROUTE,
    LOGIN_ROUTE,
    ON_BOARDING_ROUTE,
    CLIENT_REGISTRATION_ROUTE,
    CLIENT_REGISTRATION_CONSENT_ROUTE
};
