import "./ClientContactInformationComponent.scss";
import * as Yup from "yup";
import {IClientContactDetails} from "../../../shared/models/client.model";
import {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";
import {Field, FieldArray, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import {ImageConfig, Misc} from "../../../constants";
import FormikInputComponent from "../../../shared/components/form-controls/formik-input/FormikInputComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import IconButtonComponent from "../../../shared/components/icon-button/IconButtonComponent";
import FormikSelectDropdownComponent
    from "../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
import FormikPhoneInputComponent from "../../../shared/components/formik-phone-input/FormikPhoneInputComponent";
import FormControlLabelComponent from "../../../shared/components/form-control-label/FormControlLabelComponent";
import FormDebuggerComponent from "../../../shared/components/form-debugger/FormDebuggerComponent";

interface ClientContactInformationComponentProps {
    onNext: () => void;
}


const ClientContactDetailsFormValidationSchema = Yup.object({
    primary_email: Yup.string().email('Primary email should be valid').required('Primary email is required'),
    primary_contact_info: Yup.object({
        phone_type: Yup.string().required('Phone type is required'),
        phone: Yup.string().required('Phone number is required'),
    }),
    secondary_emails: Yup.array(Yup.object({
        email: Yup.string().email('Secondary email should be valid'),
    })),
});

const EmailObj = {
    email: ""
}

const PhoneObj = {
    phone_type: "",
    phone: ""
}

const ClientContactDetailsFormInitialValues: IClientContactDetails = {
    primary_email: "",
    secondary_emails: [EmailObj],
    primary_contact_info: PhoneObj,
    secondary_contact_info: [PhoneObj],
}

const ClientContactInformationComponent = (props: ClientContactInformationComponentProps) => {

    const {onNext} = props;
    const [clientContactDetailsFormInitialValues, setClientContactDetailsFormInitialValues] = useState<IClientContactDetails>(_.cloneDeep(ClientContactDetailsFormInitialValues));
    const {
        phoneTypeList,
    } = useSelector((state: IRootReducerState) => state.staticData);

    const {
        clientBasicDetails,
        isClientBasicDetailsLoaded,
    } = useSelector((state: IRootReducerState) => state.client);

    useEffect(() => {
        if (isClientBasicDetailsLoaded) {
            const clientBasicDetailsCopy = _.cloneDeep(clientBasicDetails);
            const clientContactDetails = {
                primary_email: clientBasicDetailsCopy?.primary_email,
                secondary_emails: clientBasicDetailsCopy?.secondary_emails,
                primary_contact_info: clientBasicDetailsCopy?.primary_contact_info,
                secondary_contact_info: clientBasicDetailsCopy?.secondary_contact_info,
            };
            if (!clientContactDetails?.primary_email) {
                clientContactDetails.primary_email = "";
            }
            if (!clientContactDetails?.primary_contact_info) {
                clientContactDetails.primary_contact_info = PhoneObj;
            }
            if (clientContactDetails?.secondary_emails?.length === 0) {
                clientContactDetails.secondary_emails = [EmailObj];
            }
            if (clientContactDetails?.secondary_contact_info?.length === 0) {
                clientContactDetails.secondary_contact_info = [PhoneObj];
            }
            setClientContactDetailsFormInitialValues(clientContactDetails);
        }
    }, [clientBasicDetails, isClientBasicDetailsLoaded]);

    const onSubmit = useCallback((values: any, {setSubmitting, setErrors}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = CommonService.removeKeysFromJSON(_.cloneDeep(values), ["phone_type_details"]);
        CommonService._client.ClientBasicDetailsSaveAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setSubmitting(false);
                onNext();
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                setSubmitting(false);
            })
    }, [onNext]);

    return (
        <div className={'client-contact-information-component'}>
            <Formik
                validationSchema={ClientContactDetailsFormValidationSchema}
                initialValues={clientContactDetailsFormInitialValues}
                validateOnChange={false}
                validateOnBlur={true}
                enableReinitialize={true}
                validateOnMount={true}
                onSubmit={onSubmit}
            >
                {({values, validateForm, isValid, isSubmitting}) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        validateForm();
                    }, [validateForm, values]);
                    return (
                        <Form className="t-form" noValidate={true}>
                            <FormDebuggerComponent values={values} showDebugger={false}/>
                            <div className="t-form-controls">
                                <FormControlLabelComponent label={'Primary Phone'}/>
                                <div className="ts-row">
                                    <div className="ts-col-md-5">
                                        <Field name={'primary_contact_info.phone_type'}>
                                            {
                                                (field: FieldProps) => (
                                                    <FormikSelectDropdownComponent
                                                        id={'phone_type_dd'}
                                                        options={phoneTypeList}
                                                        label={'Phone Type'}
                                                        placeholder={'Select Phone Type'}
                                                        required={true}
                                                        formikField={field}
                                                        fullWidth={true}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div className="ts-col-md-5">
                                        <Field name={'primary_contact_info.phone'}>
                                            {
                                                (field: FieldProps) => (
                                                    <FormikPhoneInputComponent
                                                        label={'Phone Number'}
                                                        placeholder={'Enter Phone Number'}
                                                        required={true}
                                                        formikField={field}
                                                        fullWidth={true}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div className="ts-col-md-2 d-flex align-items-center">
                                        <IconButtonComponent className={"form-helper-icon"}>
                                            <ToolTipComponent
                                                showArrow={true}
                                                position={"left"}
                                                tooltip={"This phone number will be used to communicate with you in case of emergency. Ensure that this number is constantly operational."}>
                                                <ImageConfig.InfoIcon/>
                                            </ToolTipComponent>
                                        </IconButtonComponent>
                                    </div>
                                </div>
                                <FormControlLabelComponent label={'Alternate Phone'}/>
                                <FieldArray
                                    name="secondary_contact_info"
                                    render={(arrayHelpers) => (
                                        <>
                                            {values?.secondary_contact_info && values?.secondary_contact_info?.map((item: any, index: any) => {
                                                return (
                                                    <div className="ts-row" key={index}>
                                                        <div className="ts-col-md-5">
                                                            <Field name={`secondary_contact_info[${index}].phone_type`}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikSelectDropdownComponent
                                                                            options={phoneTypeList}
                                                                            label={'Phone Type'}
                                                                            placeholder={'Select Phone Type'}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className="ts-col-md-5">
                                                            <Field name={`secondary_contact_info[${index}].phone`}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikPhoneInputComponent
                                                                            label={'Phone Number'}
                                                                            placeholder={'Enter Phone Number'}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className="ts-col-md-2 d-flex align-items-center">
                                                            <IconButtonComponent className={"form-helper-icon"}
                                                                                 onClick={() => {
                                                                                     arrayHelpers.push({
                                                                                         phone_type: undefined,
                                                                                         phone: undefined
                                                                                     });
                                                                                 }}
                                                            >
                                                                <ImageConfig.AddCircleIcon/>
                                                            </IconButtonComponent>
                                                            {index > 0 &&
                                                                <IconButtonComponent className={"form-helper-icon"}
                                                                                     onClick={() => {
                                                                                         arrayHelpers.remove(index);
                                                                                     }}
                                                                >
                                                                    <ImageConfig.DeleteIcon/>
                                                                </IconButtonComponent>}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                    )}/>
                                <FormControlLabelComponent label={'Primary Email Address'}/>
                                <div className="ts-row">
                                    <div className="ts-col-md-5">
                                        <Field name={'primary_email'}>
                                            {
                                                (field: FieldProps) => (
                                                    <FormikInputComponent
                                                        id={'email_primary_input'}
                                                        label={'Email (Primary)'}
                                                        placeholder={'Email (Primary)'}
                                                        type={"email"}
                                                        required={true}
                                                        formikField={field}
                                                        fullWidth={true}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div className="ts-col-md-2 d-flex align-items-center">
                                        <IconButtonComponent className={"form-helper-icon"}>
                                            <ToolTipComponent
                                                showArrow={true}
                                                position={"right"}
                                                tooltip={"This email will be used to communicate with you in case of emergency. Ensure that this email is constantly operational."}>
                                                <ImageConfig.InfoIcon/>
                                            </ToolTipComponent>
                                        </IconButtonComponent>
                                    </div>
                                </div>
                                <FormControlLabelComponent label={'Alternate Email Address'}/>
                                <FieldArray
                                    name="secondary_emails"
                                    render={(arrayHelpers) => (
                                        <>
                                            {values?.secondary_emails && values?.secondary_emails?.map((item: any, index: any) => {
                                                return (
                                                    <div className="ts-row" key={index}>
                                                        <div className="ts-col-md-5">
                                                            <Field name={`secondary_emails[${index}].email`}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Email'}
                                                                            placeholder={'Email'}
                                                                            type={"email"}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className="ts-col-md-2 d-flex align-items-center">
                                                            <IconButtonComponent className={"form-helper-icon"}
                                                                                 onClick={() => {
                                                                                     arrayHelpers.push({
                                                                                         email: undefined,
                                                                                     });
                                                                                 }}
                                                            >
                                                                <ImageConfig.AddCircleIcon/>
                                                            </IconButtonComponent>
                                                            {index > 0 &&
                                                                <IconButtonComponent className={"form-helper-icon"}
                                                                                     onClick={() => {
                                                                                         arrayHelpers.remove(index);
                                                                                     }}
                                                                >
                                                                    <ImageConfig.DeleteIcon/>
                                                                </IconButtonComponent>}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                    )}/>
                            </div>
                            <div className="t-form-actions">
                                <ButtonComponent
                                    id={'next_btn'}
                                    type={"submit"}
                                    suffixIcon={<ImageConfig.ArrowRightCircle/>}
                                    disabled={!isValid || isSubmitting}
                                    isLoading={isSubmitting}
                                >
                                    Next
                                </ButtonComponent>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );


};

export default ClientContactInformationComponent;
