import "./ClientSuccessScreen.scss";
import React from "react";
import SuccessPopupComponent from "../../shared/components/success-popup/SuccessPopupComponent";
import {useSearchParams} from "react-router-dom";

interface ClientInjuryDetailsScreenProps {

}

const ClientSuccessScreen = (props: ClientInjuryDetailsScreenProps) => {

    const [searchParams] = useSearchParams();
    const context = searchParams.get('context');
    let description;
    if (context === 'injury-form') {
        description = 'Congratulations, you have completed New Injury/Condition Information.';
    } else if (context === 'client-registration') {
        description = 'Congratulations, you have completed your Client Portal Registration.';
    } else {
        description = 'Congratulations!';
    }

    return (
        <div className={'client-injury-success-screen'}>
            <div className={'client-injury-details-success-popup-wrapper'}>
                <SuccessPopupComponent
                    description={description}
                />
            </div>
        </div>
    );

};

export default ClientSuccessScreen;
