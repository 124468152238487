import "./ClientRegistrationMedicalHistoryPersonalHabitsComponent.scss";
import FormControlLabelComponent from "../../shared/components/form-control-label/FormControlLabelComponent";
import ButtonComponent from "../../shared/components/button/ButtonComponent";
import {ImageConfig, Misc, Patterns} from "../../constants";
import * as Yup from "yup";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import FormikRadioButtonGroupComponent
    from "../../shared/components/form-controls/formik-radio-button/FormikRadioButtonComponent";
import FormikInputComponent from "../../shared/components/form-controls/formik-input/FormikInputComponent";
import {useCallback, useEffect, useState} from "react";
import {CommonService} from "../../shared/services";
import _ from "lodash";
import HorizontalLineComponent from "../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import {IAPIResponseType} from "../../shared/models/api.model";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../store/reducers";

interface ClientRegistrationMedicalHistoryPersonalHabitsComponentProps {
    onNext: () => void;
}

const ClientPersonalHabitsFormValidationSchema = Yup.object({
    personal_habits: Yup.object({
        "Smoke/Chew Tobacco?": Yup.object({
            value: Yup.string().required('Smoke/Chew Tobacco is required'),
            text: Yup.string().when("value", {
                is: "Yes",
                then: Yup.string().required('Smoke/Chew Tobacco is required')
            })
        }),
        "Drink Alcohol?": Yup.object({
            value: Yup.string().required('Drink Alcohol is required'),
            text: Yup.string().when("value", {
                is: "Yes",
                then: Yup.string().required('Drink Alcohol is required')
            })
        }),
        "Drink Coffee?": Yup.object({
            value: Yup.string().required('Drink Coffee is required'),
            text: Yup.string().when("value", {
                is: "Yes",
                then: Yup.string().required('Drink Coffee is required')
            })
        }),
        "Drink Soda/Pop?": Yup.object({
            value: Yup.string().required('Drink Soda/Pop is required'),
            text: Yup.string().when("value", {
                is: "Yes",
                then: Yup.string().required('Drink Soda/Pop is required')
            })
        }),
    }),
});

const ClientPersonalHabitsFormInitialValues: any = {
    "personal_habits": {
        "Smoke/Chew Tobacco?": {
            "value": "",
            "text": ""
        },
        "Drink Alcohol?": {
            "value": "",
            "text": ""
        },
        "Drink Coffee?": {
            "value": "",
            "text": ""
        },
        "Drink Soda/Pop?": {
            "value": "",
            "text": ""
        }
    }
};

const FormQuestions = [
    {
        key: "Smoke/Chew Tobacco?",
        title: "Smoke/Chew Tobacco?",
        placeholder: "Cigarettes/day",
        id: "smoke"
    },
    {
        key: "Drink Alcohol?",
        title: "Drink Alcohol?",
        placeholder: "Drinks/day",
        id: "alcohol"
    },
    {
        key: "Drink Coffee?",
        title: "Drink Coffee?",
        placeholder: "Cups/day",
        id: "coffee"
    },
    {
        key: "Drink Soda/Pop?",
        title: "Drink Soda/Pop?",
        placeholder: "Cups/day",
        id: "soda"
    }
]

const ClientRegistrationMedicalHistoryPersonalHabitsComponent = (props: ClientRegistrationMedicalHistoryPersonalHabitsComponentProps) => {

    const {onNext} = props;
    const [clientPersonalHabitsFormInitialValues, setClientPersonalHabitsFormInitialValues] = useState<any>(_.cloneDeep(ClientPersonalHabitsFormInitialValues));

    const {
        clientMedicalHistoryDetails,
        isClientMedicalHistoryDetailsLoaded,
    } = useSelector((state: IRootReducerState) => state.client);

    useEffect(() => {
        if (clientMedicalHistoryDetails) {
            setClientPersonalHabitsFormInitialValues(clientMedicalHistoryDetails);
        }
    }, [clientMedicalHistoryDetails, isClientMedicalHistoryDetailsLoaded]);

    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {...values, is_medical_questionnaire_details_updated: true};
        CommonService._client.ClientMedicalHistoryQuestionnairePersonalDetailsSaveAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setSubmitting(false);
                onNext();
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error, true);
                setSubmitting(false);
            })
    }, [onNext]);

    return (
        <div className={'client-registration-medical-history-personal-habits-component'}>
            <FormControlLabelComponent label={'Have you ever or do you currently:'}/>
            <Formik
                validationSchema={ClientPersonalHabitsFormValidationSchema}
                initialValues={clientPersonalHabitsFormInitialValues}
                onSubmit={onSubmit}
                validateOnChange={false}
                validateOnBlur={true}
                enableReinitialize={true}
                validateOnMount={true}>
                {({values, setFieldValue, isValid, isSubmitting, validateForm}) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        validateForm();
                    }, [validateForm, values]);
                    return (
                        <Form noValidate={true} className={"t-form form-question-list"}>
                            {
                                FormQuestions.map((question: any, index) => {
                                    const {key, title, placeholder} = question;
                                    return <>
                                        <div className="ts-row ts-align-items-center form-question" key={key}>
                                            <div className="ts-col-md-4  form-question-title">
                                                {title} *
                                            </div>
                                            <div className="ts-col-md-3 form-question-ans">
                                                <Field name={`personal_habits.${key}.value`}>
                                                    {
                                                        (field: FieldProps) => (
                                                            <FormikRadioButtonGroupComponent
                                                                options={CommonService._staticData.yesNoOptions}
                                                                displayWith={(option) => option.title}
                                                                valueExtractor={(option) => option.title}
                                                                required={true}
                                                                formikField={field}
                                                                id={`radio`}
                                                                onChange={(value) => {
                                                                    if (value === "No") {
                                                                        setFieldValue(`personal_habits.${key}.text`, undefined);
                                                                    }
                                                                }}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                            </div>
                                            <div className="ts-col-md-3 form-question-extra-ans">
                                                {
                                                    // @ts-ignore
                                                    values.personal_habits[key]?.value === 'Yes' &&
                                                    <>
                                                        <Field name={`personal_habits.${key}.text`}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        type={"text"}
                                                                        size={"small"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                        validationPattern={Patterns.POSITIVE_WHOLE_NUMBERS}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                        <span> {placeholder}</span>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <HorizontalLineComponent/>
                                    </>
                                })
                            }
                            <div className="t-form-actions">
                                <ButtonComponent
                                    id={"next_btn"}
                                    type={"submit"}
                                    isLoading={isSubmitting}
                                    disabled={isSubmitting || !isValid}
                                    suffixIcon={<ImageConfig.ArrowRightCircle/>}
                                >
                                    Next
                                </ButtonComponent>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );

};

export default ClientRegistrationMedicalHistoryPersonalHabitsComponent;
