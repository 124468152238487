import {ApiService} from "../index";
import {APIConfig} from "../../../constants";

const ClientInjuryDetailsSaveAPICall = (appointmentId: string, payload: any)  => {
    return ApiService[APIConfig.CLIENT_INJURY_DETAILS_SAVE.METHOD](APIConfig.CLIENT_INJURY_DETAILS_SAVE.URL(appointmentId), payload);
}

const ClientBasicDetailsSaveAPICall = (payload: any)  => {
    return ApiService[APIConfig.SAVE_CLIENT_BASIC_DETAILS.METHOD](APIConfig.SAVE_CLIENT_BASIC_DETAILS.URL, payload);
}

const GetPersonalDetailsAPICall = ()  => {
    return ApiService[APIConfig.GET_CLIENT_BASIC_DETAILS.METHOD](APIConfig.GET_CLIENT_BASIC_DETAILS.URL);
}

const ClientCommunicationReferralDetailsSaveAPICall = (payload: any)  => {
    return ApiService[APIConfig.SAVE_CLIENT_COMMUNICATION_REFERRAL_DETAILS.METHOD](APIConfig.SAVE_CLIENT_COMMUNICATION_REFERRAL_DETAILS.URL, payload);
}

const GetClientCommunicationReferralDetailsAPICall = ()  => {
    return ApiService[APIConfig.GET_CLIENT_COMMUNICATION_REFERRAL_DETAILS.METHOD](APIConfig.GET_CLIENT_COMMUNICATION_REFERRAL_DETAILS.URL);
}

const GetClientMedicalHistoryDetailsAPICall = ()  => {
    return ApiService[APIConfig.GET_CLIENT_MEDICAL_HISTORY_DETAILS.METHOD](APIConfig.GET_CLIENT_MEDICAL_HISTORY_DETAILS.URL);
}

const ClientMedicalProviderInfoDetailsSaveAPICall = (payload: any)  => {
    return ApiService[APIConfig.SAVE_CLIENT_MEDICAL_PROVIDER_INFO_DETAILS.METHOD](APIConfig.SAVE_CLIENT_MEDICAL_PROVIDER_INFO_DETAILS.URL, payload);
}

const SubmitRegistrationConsentAPICall = (payload: any)  => {
    return ApiService[APIConfig.SUBMIT_REGISTRATION_CONSENT_FORM.METHOD](APIConfig.SUBMIT_REGISTRATION_CONSENT_FORM.URL, payload);
}

const ClientMedicalHistoryQuestionnairePersonalDetailsSaveAPICall = (payload: any)  => {
    return ApiService[APIConfig.SAVE_CLIENT_MEDICAL_HISTORY_QUESTIONNAIRE_PERSONAL_HABITS_DETAILS.METHOD](APIConfig.SAVE_CLIENT_MEDICAL_HISTORY_QUESTIONNAIRE_PERSONAL_HABITS_DETAILS.URL, payload);
}

const ClientMedicalHistoryQuestionnaireMedicationSupplementsDetailsSaveAPICall = (payload: any)  => {
    return ApiService[APIConfig.SAVE_CLIENT_MEDICAL_HISTORY_QUESTIONNAIRE_MEDICATION_SUPPLEMENTS_DETAILS.METHOD](APIConfig.SAVE_CLIENT_MEDICAL_HISTORY_QUESTIONNAIRE_MEDICATION_SUPPLEMENTS_DETAILS.URL, payload);
}

const ClientMedicalHistoryQuestionnaireAllergiesDetailsSaveAPICall = (payload: any)  => {
    return ApiService[APIConfig.SAVE_CLIENT_MEDICAL_HISTORY_QUESTIONNAIRE_ALLERGIES_DETAILS.METHOD](APIConfig.SAVE_CLIENT_MEDICAL_HISTORY_QUESTIONNAIRE_ALLERGIES_DETAILS.URL, payload);
}

const ClientMedicalHistoryMedicalHistoryDetailsSaveAPICall = (payload: any)  => {
    return ApiService[APIConfig.SAVE_CLIENT_MEDICAL_HISTORY_MEDICAL_HISTORY_DETAILS.METHOD](APIConfig.SAVE_CLIENT_MEDICAL_HISTORY_MEDICAL_HISTORY_DETAILS.URL, payload);
}

const ClientMedicalHistoryFemaleOnlyDetailsSaveAPICall = (payload: any)  => {
    return ApiService[APIConfig.SAVE_CLIENT_MEDICAL_HISTORY_FEMALE_ONLY_DETAILS.METHOD](APIConfig.SAVE_CLIENT_MEDICAL_HISTORY_FEMALE_ONLY_DETAILS.URL, payload);
}

const ClientMedicalHistorySurgicalHistoryDetailsSaveAPICall = (payload: any)  => {
    return ApiService[APIConfig.SAVE_CLIENT_MEDICAL_HISTORY_SURGICAL_HISTORY_DETAILS.METHOD](APIConfig.SAVE_CLIENT_MEDICAL_HISTORY_SURGICAL_HISTORY_DETAILS.URL, payload);
}

const ClientMedicalHistoryMusculoskeletalHistoryDetailsSaveAPICall = (payload: any)  => {
    return ApiService[APIConfig.SAVE_CLIENT_MEDICAL_HISTORY_MUSCULOSKELETAL_HISTORY_DETAILS.METHOD](APIConfig.SAVE_CLIENT_MEDICAL_HISTORY_MUSCULOSKELETAL_HISTORY_DETAILS.URL, payload);
}

const ClientService = {
    ClientInjuryDetailsSaveAPICall,
    ClientBasicDetailsSaveAPICall,
    GetPersonalDetailsAPICall,
    ClientCommunicationReferralDetailsSaveAPICall,
    GetClientCommunicationReferralDetailsAPICall,
    GetClientMedicalHistoryDetailsAPICall,
    ClientMedicalProviderInfoDetailsSaveAPICall,
    SubmitRegistrationConsentAPICall,
    ClientMedicalHistoryQuestionnairePersonalDetailsSaveAPICall,
    ClientMedicalHistoryQuestionnaireMedicationSupplementsDetailsSaveAPICall,
    ClientMedicalHistoryQuestionnaireAllergiesDetailsSaveAPICall,
    ClientMedicalHistoryMedicalHistoryDetailsSaveAPICall,
    ClientMedicalHistoryFemaleOnlyDetailsSaveAPICall,
    ClientMedicalHistorySurgicalHistoryDetailsSaveAPICall,
    ClientMedicalHistoryMusculoskeletalHistoryDetailsSaveAPICall
}

export default ClientService;
