import "./ClientRegistrationScreen.scss";
import {ITimelineStep} from "../../shared/models/common.model";
import {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import TimeLineComponent from "../../shared/components/time-line/TimeLineComponent";
import {ImageConfig} from "../../constants";
import ClientRegistrationBasicDetailsComponent
    from "../client-registration-basic-details/ClientRegistrationBasicDetailsComponent";
import IconButtonComponent from "../../shared/components/icon-button/IconButtonComponent";
import {
    getClientBasicDetails,
    getClientCommunicationReferralDetails,
    getClientMedicalHistoryDetails
} from "../../store/actions/client.action";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "../../store/reducers";
import {useNavigate, useSearchParams} from "react-router-dom";
import ClientRegistrationCommunicationReferralDetailsComponent
    from "../client-registration-communication-referral-details/ClientRegistrationCommunicationReferralDetailsComponent";
import LoaderComponent from "../../shared/components/loader/LoaderComponent";
import ClientRegistrationMedicalProviderDetailsComponent
    from "../client-registration-medical-provider-details/ClientRegistrationMedicalProviderDetailsComponent";
import ClientRegistrationEmergencyContactDetailsComponent
    from "../client-registration-emergency-contact-details/ClientRegistrationEmergencyContactDetailsComponent";
import ClientRegistrationMedicalHistoryQuestionnaireComponent
    from "../client-registration-medical-history-questionnaire/ClientRegistrationMedicalHistoryQuestionnaireComponent";
import {CommonService} from "../../shared/services";
import {ClientRegistrationStepKeyType} from "../../shared/models/client.model";
import {setClientRegistrationStep, setClientRegistrationSubStep} from "../../store/actions/state-management.action";

interface ClientRegistrationScreenProps {

}


interface ClientRegistrationStep extends ITimelineStep {
    key: ClientRegistrationStepKeyType;
}

const ClientRegistrationSteps: ClientRegistrationStep[] = [
    {
        key: 'client-details',
        title: 'Client Details',
        status: 'in-progress'
    },
    {
        key: 'communication-preferences',
        title: 'Communication Preferences',
        status: 'pending'
    },
    {
        key: 'emergency-contact-details',
        title: 'Emergency Contact Details',
        status: 'pending'
    },
    {
        key: 'medical-provider-information',
        title: 'Medical Provider Information',
        status: 'pending'
    },
    {
        key: 'medical-history-questionnaire',
        title: 'Medical History Questionnaire',
        status: 'pending'
    }
];
const DEFAULT_STEP_KEY = ClientRegistrationSteps[0].key;

const ClientRegistrationScreen = (props: ClientRegistrationScreenProps) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [currentStep, setCurrentStep] = useState<ClientRegistrationStepKeyType | undefined>(undefined);
    const [clientRegistrationSteps, setClientRegistrationSteps] = useState<ITimelineStep[]>(_.cloneDeep(ClientRegistrationSteps));
    const {
        clientBasicDetails,
        isClientBasicDetailsLoading,
        clientCommunicationReferralDetails,
        isClientCommunicationReferralDetailsLoading,
        clientMedicalHistoryDetails,
        isClientMedicalHistoryDetailsLoading
    } = useSelector((state: IRootReducerState) => state.client);

    const {
        clientRegistrationStep,
        clientRegistrationSubStep
    } = useSelector((state: IRootReducerState) => state.stateManagement);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!clientBasicDetails) {
            dispatch(getClientBasicDetails());
        }
        if (!clientCommunicationReferralDetails) {
            dispatch(getClientCommunicationReferralDetails());
        }
        if (!clientMedicalHistoryDetails) {
            dispatch(getClientMedicalHistoryDetails());
        }
    }, [dispatch, clientBasicDetails, clientMedicalHistoryDetails, clientCommunicationReferralDetails]);

    useEffect(() => {
        const step: ClientRegistrationStepKeyType = searchParams.get("step") as ClientRegistrationStepKeyType;
        if (step && ClientRegistrationSteps.map((step) => step.key).includes(step)) {
            setCurrentStep(step);
            dispatch(setClientRegistrationStep(step));
        } else {
            searchParams.set("step", DEFAULT_STEP_KEY);
            setSearchParams(searchParams);
            setCurrentStep(DEFAULT_STEP_KEY);
            dispatch(setClientRegistrationStep(step));
        }
    }, [dispatch, searchParams, setSearchParams]);

    const handleNext = useCallback(() => {
        const currentIndex = ClientRegistrationSteps.findIndex((step) => step.key === currentStep);
        const nextStep = ClientRegistrationSteps[currentIndex + 1];
        if (nextStep) {
            searchParams.set("step", nextStep.key);
            setSearchParams(searchParams);
            setCurrentStep(nextStep.key);
            dispatch(setClientRegistrationStep(nextStep.key));
        } else {
            navigate(CommonService._routeConfig.ClientRegistrationConsent());
        }
    }, [dispatch, navigate, currentStep, searchParams, setSearchParams]);

    useEffect(() => {
        const currentStepIndex = ClientRegistrationSteps.findIndex((step) => step.key === currentStep);
        setClientRegistrationSteps((prevState)=>{
            const updatedSteps = _.cloneDeep(prevState);
            updatedSteps.forEach((step, index) => {
                if (index < currentStepIndex) {
                    step.status = 'completed';
                } else if (index === currentStepIndex) {
                    step.status = 'in-progress';
                } else {
                    if (step.status === 'completed') {
                        step.status = 'completed';
                    } else {
                        step.status = 'pending';
                    }
                }
            });
            return updatedSteps;
        });
    }, [currentStep, dispatch]);

    const handleStepClick = useCallback((step: ITimelineStep) => {
        const stepKey = (step as ClientRegistrationStep).key;
        switch (stepKey) {
            case 'communication-preferences':
                if (!(clientCommunicationReferralDetails && clientCommunicationReferralDetails?.is_communication_details_updated)) {
                    return;
                }
                break;
            case 'emergency-contact-details':
                if (!(clientBasicDetails && clientBasicDetails?.is_emergency_details_updated)) {
                    return;
                }
                break;
            case 'medical-provider-information':
                if (!(clientMedicalHistoryDetails && clientMedicalHistoryDetails?.is_medical_provider_details_updated)) {
                    return;
                }
                break;
            case 'medical-history-questionnaire':
                if (!(clientMedicalHistoryDetails && clientMedicalHistoryDetails?.is_medical_questionnaire_details_updated)) {
                    return;
                }
                break;
        }
        if (stepKey) {
            searchParams.set("step", stepKey);
            setSearchParams(searchParams);
            setCurrentStep(stepKey);
            dispatch(setClientRegistrationStep(stepKey));
        }
    }, [dispatch, searchParams, clientMedicalHistoryDetails, clientBasicDetails, clientCommunicationReferralDetails, setSearchParams]);

    const handlePrev = useCallback(() => {
        let nextStep = '';
        let nextSubStep = '';
        if (clientRegistrationStep === 'client-details') {
            if (clientRegistrationSubStep === 'personal-details') {
                navigate(CommonService._routeConfig.ClientOnboarding());
                return;
            } else if (clientRegistrationSubStep === 'contact-information') {
                nextSubStep = 'personal-details';
            } else if (clientRegistrationSubStep === 'address-information') {
                nextSubStep = 'contact-information';
            } else if (clientRegistrationSubStep === 'work-information') {
                nextSubStep = 'address-information';
            } else {
                navigate(CommonService._routeConfig.ClientOnboarding());
            }
        } else if (clientRegistrationStep === 'communication-preferences') {
            nextStep = 'client-details';
            nextSubStep = 'work-information';
        } else if (clientRegistrationStep === 'emergency-contact-details') {
            nextStep = 'communication-preferences';
        } else if (clientRegistrationStep === 'medical-provider-information') {
            nextStep = 'emergency-contact-details';
        } else if (clientRegistrationStep === 'medical-history-questionnaire') {
            if (clientRegistrationSubStep === 'personal-habits') {
                nextStep = 'medical-provider-information';
            } else if (clientRegistrationSubStep === 'medication-supplements') {
                nextSubStep = 'personal-habits';
            } else if (clientRegistrationSubStep === 'allergies') {
                nextSubStep = 'medication-supplements';
            } else if (clientRegistrationSubStep === 'medical-history') {
                nextSubStep = 'allergies';
            } else if (clientRegistrationSubStep === 'female-only') {
                nextSubStep = 'medical-history';
            } else if (clientRegistrationSubStep === 'surgical-history') {
                if (clientBasicDetails && clientBasicDetails?.gender === 'female') {
                    nextSubStep = 'female-only';
                } else {
                    nextSubStep = 'medical-history';
                }
            } else if (clientRegistrationSubStep === 'musculoskeletal-history') {
                nextSubStep = 'surgical-history';
            } else {
                nextStep = 'emergency-contact-details';
            }
        }
        if (nextStep) {
            searchParams.set("step", nextStep);
            setSearchParams(searchParams);
            dispatch(setClientRegistrationStep(nextStep as ClientRegistrationStepKeyType));
        }
        if (nextSubStep) {
            searchParams.set("subStep", nextSubStep);
            setSearchParams(searchParams);
            dispatch(setClientRegistrationSubStep(nextSubStep));
        }
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams, navigate, dispatch, clientBasicDetails, clientRegistrationStep, clientRegistrationSubStep]);

    useEffect(() => {
        if (clientRegistrationStep) {
            setCurrentStep(clientRegistrationStep);
        }
    }, [searchParams, setSearchParams, clientRegistrationStep]);

    return (
        <div className={'client-registration-screen'}>
            <div className="client-registration-container">
                <div className="client-registration-logo-time-line">
                    <div className="client-registration-logo">
                        <img src={ImageConfig.Logo} alt={'kinergy crp'}/>
                    </div>
                    <div className="client-registration-timeline">
                        <TimeLineComponent steps={clientRegistrationSteps} onStepClick={handleStepClick}/>
                    </div>
                </div>
                <div className="client-registration-form-container">
                    <div className="client-registration-form-back-navigation" onClick={handlePrev}>
                        <IconButtonComponent className="client-registration-form-back-navigation_icon">
                            <ImageConfig.NavigateBack/>
                        </IconButtonComponent>
                        <div className="client-registration-form-back-navigation_text">
                            Previous
                        </div>
                    </div>
                    <div className="client-registration-form-wrapper">
                        {
                            currentStep === 'client-details' &&
                            <ClientRegistrationBasicDetailsComponent onNext={handleNext}/>
                        }
                        {
                            currentStep === 'communication-preferences' &&
                            <ClientRegistrationCommunicationReferralDetailsComponent onNext={handleNext}/>
                        }
                        {
                            currentStep === 'emergency-contact-details' &&
                            <ClientRegistrationEmergencyContactDetailsComponent onNext={handleNext}/>
                        }
                        {
                            currentStep === 'medical-provider-information' &&
                            <ClientRegistrationMedicalProviderDetailsComponent onNext={handleNext}/>
                        }
                        {
                            currentStep === 'medical-history-questionnaire' &&
                            <ClientRegistrationMedicalHistoryQuestionnaireComponent onNext={handleNext}/>
                        }
                    </div>
                </div>
                {
                    (isClientBasicDetailsLoading || isClientCommunicationReferralDetailsLoading || isClientMedicalHistoryDetailsLoading) &&
                    <div className={"client-registration-loading-wrapper"}>
                        <LoaderComponent type={"spinner"}/>
                    </div>
                }
            </div>
        </div>
    );

};

export default ClientRegistrationScreen;
