import "./ClientRegistrationMedicalHistoryAllergiesComponent.scss";
import * as Yup from "yup";
import React, {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {IAPIResponseType} from "../../shared/models/api.model";
import {ImageConfig, Misc} from "../../constants";
import {CommonService} from "../../shared/services";
import ButtonComponent from "../../shared/components/button/ButtonComponent";
import FormikTextAreaComponent from "../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../store/reducers";

interface ClientRegistrationMedicalHistoryAllergiesComponentProps {
    onNext: ()=> void;
}

const ClientAllergiesValidationSchema = Yup.object({
    allergies: Yup.string().required('Allergies are required')
})

const ClientAllergiesFormInitialValues: any = {
    allergies: ''
}

const ClientRegistrationMedicalHistoryAllergiesComponent = (props: ClientRegistrationMedicalHistoryAllergiesComponentProps) => {

    const {onNext} = props;
    const [clientAllergiesFormInitialValues, setClientAllergiesFormInitialValues] = useState<any>(_.cloneDeep(ClientAllergiesFormInitialValues));

    const {
        clientMedicalHistoryDetails,
        isClientMedicalHistoryDetailsLoaded,
    } = useSelector((state: IRootReducerState) => state.client);

    useEffect(() => {
        if (clientMedicalHistoryDetails) {
            setClientAllergiesFormInitialValues(clientMedicalHistoryDetails);
        }
    }, [clientMedicalHistoryDetails, isClientMedicalHistoryDetailsLoaded]);

    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        const payload = {...values};
        setSubmitting(true);
        CommonService._client.ClientMedicalHistoryQuestionnaireAllergiesDetailsSaveAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setSubmitting(false);
                setClientAllergiesFormInitialValues(_.cloneDeep(values));
                onNext();
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                setSubmitting(false);
            })
    }, [onNext]);

    return (
        <div className={'client-registration-medical-history-allergies-component'}>
            <Formik initialValues={clientAllergiesFormInitialValues}
                    validationSchema={ClientAllergiesValidationSchema}
                    onSubmit={onSubmit}
                    validateOnChange={false}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    validateOnMount={true}>
                {({values, isValid, isSubmitting, validateForm}) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        validateForm();
                    }, [validateForm, values]);
                    return (
                        <Form noValidate={true} className={"t-form"}>
                            <Field name={'allergies'}>
                                {
                                    (field: FieldProps) => (
                                        <FormikTextAreaComponent
                                            id={'allergies_input'}
                                            formikField={field}
                                            label={'Please list any allergies you have (ie. Medications, Food, Environmental, Insects, \n' +
                                                'Adhesives, etc.):'}
                                            required={true}
                                            fullWidth={true}
                                            placeholder={'Allergies'}/>
                                    )
                                }
                            </Field>
                            <div className="t-form-actions">
                                <ButtonComponent
                                    id={"next_btn"}
                                    type={"submit"}
                                    isLoading={isSubmitting}
                                    disabled={isSubmitting || !isValid}
                                    suffixIcon={<ImageConfig.ArrowRightCircle/>}
                                >
                                    Next
                                </ButtonComponent>
                            </div>
                        </Form>
                    )
                }}

            </Formik>
        </div>
    );

};

export default ClientRegistrationMedicalHistoryAllergiesComponent;
