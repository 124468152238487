import "./SuccessPopupComponent.scss";
import ButtonComponent from "../button/ButtonComponent";
import {ImageConfig} from "../../../constants";
import {useCallback} from "react";

interface SuccessPopupComponentProps {
    title?: string;
    description?: string;
    onCTAClick?: () => void;
}

const SuccessPopupComponent = (props: SuccessPopupComponentProps) => {

    const {onCTAClick} = props;
    const title = props.title || 'Yay!';
    const description = props.description || 'Congratulations';

    const handleClick = useCallback(() => {
        window.open('https://www.zoella.health/', '_self');
        onCTAClick && onCTAClick();
    }, [onCTAClick]);

    return (
        <div className={'success-popup-component'}>
            <div className={'success-popup-image'}>
                <img src={ImageConfig.Tick} alt={'success-popup-tick'}/>
            </div>
            <div className="success-popup-meta">
                <div className={'success-popup-title'}>
                    {title}
                </div>
                <div className={'success-popup-description'}>
                    {description}
                </div>
                <div className={'success-popup-cta'}>
                    <ButtonComponent onClick={handleClick}>
                       Learn more
                    </ButtonComponent>
                </div>
            </div>
        </div>
    );

};

export default SuccessPopupComponent;
