import "./ClientInjuryDetailsScreen.scss";
import {useNavigate, useParams} from "react-router-dom";
import {Field, FieldArray, FieldProps, Form, Formik, FormikHelpers} from 'formik';
import {ImageConfig, Misc} from "../../constants";
import ButtonComponent from "../../shared/components/button/ButtonComponent";
import * as Yup from "yup";
import {IClientInjuryDetails} from "../../shared/models/client.model";
import React, {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import FormDebuggerComponent from "../../shared/components/form-debugger/FormDebuggerComponent";
import FormikDatePickerComponent
    from "../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent";
import {IBodyPart} from "../../shared/models/static-data.model";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../store/reducers";
import IconButtonComponent from "../../shared/components/icon-button/IconButtonComponent";
import FormikSelectDropdownComponent
    from "../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
import FormikTextAreaComponent from "../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent";
import FormikRadioButtonGroupComponent
    from "../../shared/components/form-controls/formik-radio-button/FormikRadioButtonComponent";
import {CommonService} from "../../shared/services";
import FormikInputComponent from "../../shared/components/form-controls/formik-input/FormikInputComponent";
import FormikPhoneInputComponent from "../../shared/components/formik-phone-input/FormikPhoneInputComponent";
import {IAPIResponseType} from "../../shared/models/api.model";

const BODY_PART = {
    body_part_id: "",
    body_part_details: "",
    body_side: "",
};

const BodyPartsValidationSchema = Yup.object().shape({
    body_part_id: Yup.mixed().required("Required"),
    body_part_details: Yup.mixed().nullable(),
    body_side: Yup.mixed().nullable().when("body_part_details", {
        is: (value: IBodyPart) => value && value?.sides && value?.sides?.length > 0,
        then: Yup.mixed().required('Required'),
        otherwise: Yup.mixed().nullable()
    }),
});

const ClientInjuryDetailsFormInitialValues: IClientInjuryDetails = {
    "onset_date": "",
    "surgery_date": "",
    "injury_body_parts": [
        BODY_PART
    ],
    "injury_occurrence_details": "",
    "pain_level": 0,
    "injury_aggravation_details": "",
    "injury_healing_details": "",
    "prev_injury_occurrence": {
        "is_injury_occurred_before": true,
        "prev_injury_details": ''
    },
    "is_treated": true,
    "diagnostic_details": {
        "is_diagnosed": true,
        "name": "",
        "phone": "",
        "next_appointment": "",
        "diagnosis": ""
    }
}

const ClientInjuryDetailsFormValidationSchema = Yup.object({
    onset_date: Yup.mixed().required('Required'),
    surgery_date: Yup.mixed().required('Required'),
    injury_body_parts: Yup.array().of(BodyPartsValidationSchema),
    injury_occurrence_details: Yup.mixed().required('Required'),
    pain_level: Yup.mixed().required('Required'),
    injury_aggravation_details: Yup.mixed().required('Required'),
    injury_healing_details: Yup.mixed().required('Required'),
    is_treated: Yup.mixed().required('Required'),
    prev_injury_occurrence: Yup.object({
        is_injury_occurred_before: Yup.mixed().required('Required'),
        prev_injury_details: Yup.mixed().nullable().when("is_injury_occurred_before", {
            is: (is_injury_occurred_before: boolean) => is_injury_occurred_before,
            then: Yup.mixed().required('Required'),
            otherwise: Yup.mixed().nullable()
        }),
    }),
    diagnostic_details: Yup.object({
        is_diagnosed: Yup.mixed().required('Required'),
        name: Yup.mixed().required('Required'),
        phone: Yup.mixed().required('Required'),
        next_appointment: Yup.mixed().required('Required'),
        diagnosis: Yup.mixed().nullable().when("is_diagnosed", {
            is: (is_diagnosed: boolean) => is_diagnosed,
            then: Yup.mixed().required('Required'),
            otherwise: Yup.mixed().nullable()
        }),
    })
});

interface ClientInjuryDetailsScreenProps {

}

const ClientInjuryDetailsScreen = (props: ClientInjuryDetailsScreenProps) => {

    const {appointmentId} = useParams();
    const {bodyPartList} = useSelector((state: IRootReducerState) => state.staticData);
    const [clientInjuryDetailsFormInitialValues] = useState<IClientInjuryDetails>(_.cloneDeep(ClientInjuryDetailsFormInitialValues));
    const navigate = useNavigate();

    const onSubmit = useCallback((values: any, {setSubmitting, setErrors}: FormikHelpers<any>) => {
        if (appointmentId) {
            setSubmitting(true);
            const payload = CommonService.removeKeysFromJSON(_.cloneDeep(values), ['body_part_details']);
            payload.onset_date = CommonService.convertDateFormat(payload.onset_date);
            payload.surgery_date = CommonService.convertDateFormat(payload.surgery_date);
            payload.diagnostic_details.next_appointment = CommonService.convertDateFormat(payload?.diagnostic_details?.next_appointment);
            CommonService._client.ClientInjuryDetailsSaveAPICall(appointmentId, payload)
                .then((response: IAPIResponseType<IClientInjuryDetails>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY] || 'Injured details has been saved', "success");
                    setSubmitting(false);
                    navigate(CommonService._routeConfig.ClientInjurySuccess('injury-form'));
                })
                .catch((error: any) => {
                    CommonService.handleErrors(setErrors, error);
                    setSubmitting(false);
                });
        } else {
            console.log('Client id is missing');
        }
    }, [appointmentId, navigate]);

    return (
        <div className={'client-injury-details-screen'}>
            <div className={'client-injury-details-form-wrapper'}>
                <Formik
                    validationSchema={ClientInjuryDetailsFormValidationSchema}
                    initialValues={clientInjuryDetailsFormInitialValues}
                    validateOnChange={false}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    validateOnMount={true}
                    onSubmit={onSubmit}
                >
                    {({values, setFieldValue, isSubmitting, resetForm, isValid, validateForm, errors}) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                            validateForm();
                        }, [validateForm, values]);
                        return (
                            <Form className={"t-form"} noValidate={true}>
                                <FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>
                                <div className={'client-injury-details-form-header'}>
                                    <div className={'client-injury-details-form-logo'}>
                                        <img src={ImageConfig.Logo} alt={'client-portal-branding'}/>
                                    </div>
                                    <div className={'client-injury-details-form-meta'}>
                                        <div className={'client-injury-details-form-title'}>
                                            New Injury/Condition Information
                                        </div>
                                        <div className={'client-injury-details-form-sub-title'}>
                                            Please provide as much detail below regarding your current injury/condition.
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={"client-injury-details-form-body t-form-controls client-injury-details-form-controls"}>
                                    <div className="ts-row">
                                        <div className="ts-col-sm-12 ts-col-lg">
                                            <Field name={'onset_date'} className="t-form-control">
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikDatePickerComponent
                                                            label={'Date of Onset'}
                                                            formikField={field}
                                                            required={true}
                                                            fullWidth={true}
                                                            maxDate={new Date()}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-sm-12 ts-col-lg">
                                            <Field name={'surgery_date'} className="t-form-control">
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikDatePickerComponent
                                                            label={'Surgery Date'}
                                                            formikField={field}
                                                            required={true}
                                                            fullWidth={true}
                                                            maxDate={new Date()}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-sm-12 ts-col-lg"/>
                                    </div>
                                    <FieldArray
                                        name="injury_body_parts"
                                        render={arrayHelpers => (
                                            <>
                                                {values?.injury_body_parts && values?.injury_body_parts?.map((item: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className="ts-row">
                                                                <div className="ts-col-sm-12 ts-col-lg">
                                                                    <Field
                                                                        name={`injury_body_parts[${index}].body_part_id`}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikSelectDropdownComponent
                                                                                    options={bodyPartList}
                                                                                    label={'Body Part'}
                                                                                    displayWith={(item: any) => item?.name}
                                                                                    valueExtractor={(item: any) => item?._id}
                                                                                    formikField={field}
                                                                                    required={true}
                                                                                    fullWidth={true}
                                                                                    onUpdate={(value) => {
                                                                                        setFieldValue(`injury_body_parts[${index}].body_part_details`, bodyPartList.find((item: any) => item?._id === value));
                                                                                        setFieldValue(`injury_body_parts[${index}].body_side`, '');
                                                                                    }}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Field>
                                                                </div>
                                                                <div className="ts-col-sm-12 ts-col-lg">
                                                                    <Field
                                                                        name={`injury_body_parts[${index}].body_side`}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikSelectDropdownComponent
                                                                                    disabled={(values?.injury_body_parts[index]?.body_part_details === "" || !values?.injury_body_parts[index]?.body_part_details?.sides || values?.injury_body_parts[index]?.body_part_details?.sides?.length === 0)}
                                                                                    options={values?.injury_body_parts[index]?.body_part_details?.sides}
                                                                                    label={'Body Side'}
                                                                                    formikField={field}
                                                                                    displayWith={(item: any) => item}
                                                                                    valueExtractor={(item: any) => item}
                                                                                    required={values?.injury_body_parts[index]?.body_part_details?.sides?.length > 0}
                                                                                    fullWidth={true}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Field>
                                                                </div>
                                                                <div
                                                                    className="ts-col-sm-12 ts-col-lg d-flex ts-align-items-center">
                                                                    {index > 0 &&
                                                                        <IconButtonComponent onClick={() => {
                                                                            arrayHelpers.remove(index)
                                                                        }}>
                                                                            <ImageConfig.DeleteIcon/>
                                                                        </IconButtonComponent>
                                                                    }
                                                                    {
                                                                        (index === values?.injury_body_parts?.length - 1) &&
                                                                        <IconButtonComponent
                                                                            onClick={() => {
                                                                                arrayHelpers.push(BODY_PART);
                                                                            }}
                                                                        >
                                                                            <ImageConfig.AddCircleIcon/>
                                                                        </IconButtonComponent>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </>
                                        )}/>
                                    <div className="ts-row">
                                        <div className="ts-col-sm-12 ts-col-lg">
                                            <Field name={'injury_occurrence_details'} className="t-form-control">
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikTextAreaComponent
                                                            label={'Please describe how your injury/condition occurred?'}
                                                            formikField={field}
                                                            required={true}
                                                            fullWidth={true}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-sm-12 ts-col-lg"/>
                                    </div>
                                    <div className="ts-row">
                                        <div className="ts-col-sm-12 ts-col-lg pain-level-form-control">
                                            <Field name={'pain_level'} className="t-form-control">
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikRadioButtonGroupComponent
                                                            label={'What is your current level of pain? (0 = No Pain; 10 = Worse imaginable pain)'}
                                                            formikField={field}
                                                            required={true}
                                                            options={CommonService._staticData.injurySeverityLevels}
                                                            displayWith={(item: any) => item}
                                                            keyExtractor={(item: any) => item}
                                                            valueExtractor={(item: any) => item}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-sm-12 ts-col-lg"/>
                                    </div>
                                    <div className="ts-row">
                                        <div className="ts-col-sm-12 ts-col-lg">
                                            <Field name={'injury_aggravation_details'} className="t-form-control">
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikTextAreaComponent
                                                            label={'Since the onset of your injury/condition, what has made your injury/condition feel better?'}
                                                            formikField={field}
                                                            required={true}
                                                            fullWidth={true}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-sm-12 ts-col-lg"/>
                                    </div>
                                    <div className="ts-row">
                                        <div className="ts-col-sm-12 ts-col-lg">
                                            <Field name={'injury_healing_details'} className="t-form-control">
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikTextAreaComponent
                                                            label={'What has made your injury/condition feel worse?'}
                                                            formikField={field}
                                                            required={true}
                                                            fullWidth={true}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-sm-12 ts-col-lg"/>
                                    </div>
                                    <div className="ts-row">
                                        <div
                                            className={`ts-col-sm-12 ts-col-lg radio-inline-options ${values.prev_injury_occurrence.is_injury_occurred_before ? 'injury-occurred' : ''}`}>
                                            <Field name={'prev_injury_occurrence.is_injury_occurred_before'}
                                                   className="t-form-control">
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikRadioButtonGroupComponent
                                                            label={'Has this injury/condition or similar injury/condition occurred before?'}
                                                            formikField={field}
                                                            required={true}
                                                            options={CommonService._staticData.yesNoOptions}
                                                            onChange={() => {
                                                                setFieldValue('prev_injury_occurrence.prev_injury_details', '');
                                                            }}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-sm-12 ts-col-lg"/>
                                    </div>
                                    {
                                        values.prev_injury_occurrence.is_injury_occurred_before &&
                                        <div className="ts-row">
                                            <div className="ts-col-sm-12 prev_injury_details ts-col-lg">
                                                <Field name={'prev_injury_occurrence.prev_injury_details'}
                                                       className="t-form-control">
                                                    {
                                                        (field: FieldProps) => (
                                                            <FormikTextAreaComponent
                                                                label={'If so, please provide details below (ie. when, what happened, etc) :'}
                                                                formikField={field}
                                                                required={true}
                                                                fullWidth={true}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                            </div>
                                            <div className="ts-col-sm-12 ts-col-lg"/>
                                        </div>
                                    }
                                    <div className="ts-row">
                                        <div className="ts-col-sm-12 ts-col-lg radio-inline-options">
                                            <Field name={'is_treated'} className="t-form-control">
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikRadioButtonGroupComponent
                                                            label={'Have you seen a doctor for this injury/condition:'}
                                                            formikField={field}
                                                            required={true}
                                                            options={CommonService._staticData.yesNoOptions}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-sm-12 ts-col-lg"/>
                                    </div>
                                    <div className="ts-row">
                                        <div className="ts-col-sm-12 ts-col-lg  radio-inline-options">
                                            <Field name={'diagnostic_details.is_diagnosed'} className="t-form-control">
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikRadioButtonGroupComponent
                                                            label={'Have you had any diagnostics for this injury/condition (MRI, XRAY, etc):'}
                                                            formikField={field}
                                                            required={true}
                                                            options={CommonService._staticData.yesNoOptions}
                                                            onChange={() => {
                                                                setFieldValue('diagnostic_details.diagnosis', '');
                                                            }}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-sm-12 ts-col-lg"/>
                                    </div>
                                    <div className="ts-row">
                                        <div className="ts-col-sm-12 ts-col-lg">
                                            <Field name={'diagnostic_details.name'} className="t-form-control">
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikInputComponent
                                                            label={'Name of Doctor'}
                                                            formikField={field}
                                                            required={true}
                                                            fullWidth={true}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-sm-12 ts-col-lg">
                                            <Field name={'diagnostic_details.phone'} className="t-form-control">
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikPhoneInputComponent
                                                            label={'Doctor Phone Number'}
                                                            formikField={field}
                                                            required={true}
                                                            fullWidth={true}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-sm-12 ts-col-lg"/>
                                    </div>
                                    <div className="ts-row">
                                        <div className="ts-col-sm-12 ts-col-lg">
                                            <Field name={'diagnostic_details.next_appointment'}
                                                   className="t-form-control">
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikDatePickerComponent
                                                            label={'Date of Next Doctor\'s Appointment'}
                                                            formikField={field}
                                                            required={true}
                                                            fullWidth={true}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-sm-12 ts-col-lg">
                                            {values.diagnostic_details.is_diagnosed &&
                                                <Field name={'diagnostic_details.diagnosis'} className="t-form-control">
                                                    {
                                                        (field: FieldProps) => (
                                                            <FormikInputComponent
                                                                label={'Medical Diagnosis'}
                                                                formikField={field}
                                                                required={true}
                                                                fullWidth={true}
                                                            />
                                                        )
                                                    }
                                                </Field>}
                                        </div>
                                        <div className="ts-col-sm-12 ts-col-lg"/>
                                    </div>
                                    <div className={'t-form-actions'}>
                                        <ButtonComponent
                                            type={"submit"}
                                            isLoading={isSubmitting}
                                            // disabled={!isValid}
                                        >
                                            Submit
                                        </ButtonComponent>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                    }
                </Formik>
            </div>
        </div>
    );

};

export default ClientInjuryDetailsScreen;
