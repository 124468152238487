import "./ClientRegistrationEmergencyContactDetailsComponent.scss";
import * as Yup from "yup";
import {IClientEmergencyContactDetails,} from "../../shared/models/client.model";
import {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "../../store/reducers";
import {Field, FieldArray, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {CommonService} from "../../shared/services";
import {IAPIResponseType} from "../../shared/models/api.model";
import {ImageConfig, Misc} from "../../constants";
import FormDebuggerComponent from "../../shared/components/form-debugger/FormDebuggerComponent";
import FormikInputComponent from "../../shared/components/form-controls/formik-input/FormikInputComponent";
import FormikSelectDropdownComponent
    from "../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
import ButtonComponent from "../../shared/components/button/ButtonComponent";
import FormControlLabelComponent from "../../shared/components/form-control-label/FormControlLabelComponent";
import IconButtonComponent from "../../shared/components/icon-button/IconButtonComponent";
import ToolTipComponent from "../../shared/components/tool-tip/ToolTipComponent";
import HorizontalLineComponent from "../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import {getClientBasicDetails} from "../../store/actions/client.action";
import FormikPhoneInputComponent from "../../shared/components/formik-phone-input/FormikPhoneInputComponent";

interface ClientRegistrationEmergencyContactDetailsComponentProps {
    onNext: () => void;
}

const ClientEmergencyContactDetailsFormValidationSchema = Yup.object({
    emergency_contact_info: Yup.object({
        primary_emergency: Yup.object({
            name: Yup.string().required('Full Name is required'),
            relationship: Yup.string().required('Relationship is required'),
            language: Yup.string().required('Language is required'),
            primary_contact_info: Yup.object({
                phone_type: Yup.string().required('Phone Type is required'),
                phone: Yup.string().required('Phone Number is required'),
            })
        })
    }),
});

const ClientEmergencyContactDetailsFormInitialValues: IClientEmergencyContactDetails = {
    show_secondary_emergency_form: false,
    emergency_contact_info: {
        primary_emergency: {
            name: "",
            relationship: "",
            language: "",
            primary_contact_info: {
                phone_type: "",
                phone: ""
            },
            secondary_contact_info: [
                {
                    phone_type: "",
                    phone: ""
                }
            ]
        },
        secondary_emergency: {
            name: "",
            relationship: "",
            language: "",
            primary_contact_info: {
                phone_type: "",
                phone: ""
            },
            secondary_contact_info: [
                {
                    phone_type: "",
                    phone: ""
                }
            ]
        }
    },
}


const ClientRegistrationEmergencyContactDetailsComponent = (props: ClientRegistrationEmergencyContactDetailsComponentProps) => {

    const {onNext} = props;
    const dispatch = useDispatch();
    const [clientEmergencyContactDetailsFormInitialValues, setClientEmergencyContactDetailsFormInitialValues] = useState<IClientEmergencyContactDetails>(_.cloneDeep(ClientEmergencyContactDetailsFormInitialValues));

    const {
        clientBasicDetails,
        isClientBasicDetailsLoaded,
    } = useSelector((state: IRootReducerState) => state.client);

    const {
        phoneTypeList,
        relationshipList,
        languageList
    } = useSelector((state: IRootReducerState) => state.staticData);

    useEffect(() => {
        if (isClientBasicDetailsLoaded) {
            if (!clientBasicDetails?.emergency_contact_info?.primary_emergency?.secondary_contact_info ||
                (clientBasicDetails?.emergency_contact_info?.primary_emergency?.secondary_contact_info && clientBasicDetails?.emergency_contact_info?.primary_emergency?.secondary_contact_info?.length === 0)) {
                clientBasicDetails.emergency_contact_info.primary_emergency.secondary_contact_info = [{
                    phone: "",
                    phone_type: ""
                }]
            }
            if (clientBasicDetails?.emergency_contact_info?.secondary_emergency) {
                clientBasicDetails.show_secondary_emergency_form = true;
                if (!clientBasicDetails?.emergency_contact_info?.secondary_emergency.secondary_contact_info ||
                    (clientBasicDetails?.emergency_contact_info?.secondary_emergency.secondary_contact_info && clientBasicDetails?.emergency_contact_info?.secondary_emergency?.secondary_contact_info?.length === 0)) {
                    clientBasicDetails.emergency_contact_info.secondary_emergency.secondary_contact_info = [{
                        phone: "",
                        phone_type: ""
                    }]
                }
            }
            setClientEmergencyContactDetailsFormInitialValues(clientBasicDetails);
        }
    }, [clientBasicDetails, isClientBasicDetailsLoaded]);

    const onSubmit = useCallback((values: any, {setSubmitting, setErrors}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {...values, is_emergency_details_updated: true};
        CommonService._client.ClientBasicDetailsSaveAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setSubmitting(false);
                dispatch(getClientBasicDetails());
                onNext();
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                setSubmitting(false);
            })
    }, [dispatch, onNext]);

    return (
        <div
            className={'client-registration-details-block client-registration-emergency-contact-details-component'}>
            <div className="client-registration-details-block__header">
                <div className="client-registration-details-block__title">
                    Emergency Contact Details
                </div>
            </div>
            <div className="client-registration-details-block__body">
                <Formik
                    validationSchema={ClientEmergencyContactDetailsFormValidationSchema}
                    initialValues={clientEmergencyContactDetailsFormInitialValues}
                    validateOnChange={false}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    validateOnMount={true}
                    onSubmit={onSubmit}
                >
                    {({values, setFieldValue, validateForm, isValid, isSubmitting}) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                            validateForm();
                        }, [validateForm, values]);
                        return (
                            <Form className="t-form" noValidate={true}>
                                <FormDebuggerComponent values={values} showDebugger={false}/>
                                <div className="t-form-controls">
                                    <FormControlLabelComponent label={"Primary Emergency Contact"}/>
                                    <div className="ts-row">
                                        <div className="ts-col-md-5">
                                            <Field name={'emergency_contact_info.primary_emergency.name'}>
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikInputComponent
                                                            id={'full_name_input'}
                                                            label={'Full Name'}
                                                            placeholder={'Full Name'}
                                                            type={"text"}
                                                            required={true}
                                                            titleCase={true}
                                                            formikField={field}
                                                            fullWidth={true}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-md-5">
                                            <Field name={'emergency_contact_info.primary_emergency.relationship'}>
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikSelectDropdownComponent
                                                            id={'relationship_dd'}
                                                            options={relationshipList}
                                                            label={'Relationship'}
                                                            required={true}
                                                            formikField={field}
                                                            fullWidth={true}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-md-2"></div>
                                    </div>
                                    <div className="ts-row">
                                        <div className="ts-col-md-5">
                                            <Field name={'emergency_contact_info.primary_emergency.language'}>
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikSelectDropdownComponent
                                                            id={'language_dd'}
                                                            options={languageList}
                                                            label={'Language'}
                                                            required={true}
                                                            formikField={field}
                                                            fullWidth={true}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-md-5">
                                        </div>
                                        <div className="ts-col-md-2"></div>
                                    </div>
                                    <FormControlLabelComponent label={'Primary Phone'}/>
                                    <div className="ts-row">
                                        <div className="ts-col-md-5">
                                            <Field
                                                name={'emergency_contact_info.primary_emergency.primary_contact_info.phone_type'}>
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikSelectDropdownComponent
                                                            id={"p_phone_type"}
                                                            options={phoneTypeList}
                                                            label={'Phone Type'}
                                                            required={true}
                                                            formikField={field}
                                                            fullWidth={true}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-md-5">
                                            <Field
                                                name={'emergency_contact_info.primary_emergency.primary_contact_info.phone'}>
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikPhoneInputComponent
                                                            id={"p_phone_number"}
                                                            label={'Phone Number'}
                                                            placeholder={'Phone Number'}
                                                            required={true}
                                                            formikField={field}
                                                            fullWidth={true}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-md-2 d-flex align-items-center">
                                            <IconButtonComponent className={"form-helper-icon"}>
                                                <ToolTipComponent
                                                    showArrow={true}
                                                    position={"left"}
                                                    tooltip={"This phone number will be used to communicate with you in case of emergency. Ensure that this number is constantly operational."}>
                                                    <ImageConfig.InfoIcon/>
                                                </ToolTipComponent>
                                            </IconButtonComponent>
                                        </div>
                                    </div>
                                    <FormControlLabelComponent label={'Alternate Phone'}/>
                                    <FieldArray
                                        name="emergency_contact_info.primary_emergency.secondary_contact_info"
                                        render={(arrayHelpers) => (
                                            <>
                                                {values?.emergency_contact_info?.primary_emergency?.secondary_contact_info && values?.emergency_contact_info?.primary_emergency?.secondary_contact_info?.map((item: any, index: any) => {
                                                    return (
                                                        <div className="ts-row" key={index}>
                                                            <div className="ts-col-md-5">
                                                                <Field
                                                                    name={`emergency_contact_info.primary_emergency.secondary_contact_info[${index}].phone_type`}>
                                                                    {
                                                                        (field: FieldProps) => (
                                                                            <FormikSelectDropdownComponent
                                                                                options={phoneTypeList}
                                                                                label={'Phone Type'}
                                                                                formikField={field}
                                                                                fullWidth={true}
                                                                            />
                                                                        )
                                                                    }
                                                                </Field>
                                                            </div>
                                                            <div className="ts-col-md-5">
                                                                <Field
                                                                    name={`emergency_contact_info.primary_emergency.secondary_contact_info[${index}].phone`}>
                                                                    {
                                                                        (field: FieldProps) => (
                                                                            <FormikPhoneInputComponent
                                                                                label={'Phone Number'}
                                                                                placeholder={'Phone Number'}
                                                                                formikField={field}
                                                                                fullWidth={true}
                                                                            />
                                                                        )
                                                                    }
                                                                </Field>
                                                            </div>
                                                            <div className="ts-col-md-2 d-flex align-items-center">
                                                                <IconButtonComponent className={"form-helper-icon"}
                                                                                     onClick={() => {
                                                                                         arrayHelpers.push({
                                                                                             phone_type: undefined,
                                                                                             phone: undefined
                                                                                         });
                                                                                     }}
                                                                >
                                                                    <ImageConfig.AddCircleIcon/>
                                                                </IconButtonComponent>
                                                                {index > 0 &&
                                                                    <IconButtonComponent className={"form-helper-icon"}
                                                                                         onClick={() => {
                                                                                             arrayHelpers.remove(index);
                                                                                         }}
                                                                    >
                                                                        <ImageConfig.DeleteIcon/>
                                                                    </IconButtonComponent>}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </>
                                        )}/>
                                    {
                                        !values.show_secondary_emergency_form && <div>
                                            <ButtonComponent variant={"link"}
                                                             color={"accent"}
                                                             prefixIcon={<ImageConfig.AddCircleIcon/>}
                                                             onClick={() => {
                                                                 setFieldValue('show_secondary_emergency_form', true);
                                                             }}
                                            >
                                                Add Another Contact
                                            </ButtonComponent>
                                        </div>
                                    }
                                    <>
                                        {
                                            values.show_secondary_emergency_form && <>
                                                <HorizontalLineComponent/>
                                                <div
                                                    className="d-flex flex-1 justify-content-space-between align-items-center mrg-bottom-20">
                                                    <FormControlLabelComponent className={"mrg-bottom-0"}
                                                                               label={"Secondary Emergency Contact"}/>
                                                    <ButtonComponent variant={"outlined"}
                                                                     color={"error"}
                                                                     size={"small"}
                                                                     prefixIcon={<ImageConfig.DeleteIcon/>}
                                                                     onClick={() => {
                                                                         setFieldValue('show_secondary_emergency_form', false);
                                                                     }}
                                                    >
                                                        Delete
                                                    </ButtonComponent>
                                                </div>
                                                <div className="ts-row">
                                                    <div className="ts-col-md-5">
                                                        <Field name={'emergency_contact_info.secondary_emergency.name'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Full Name'}
                                                                        placeholder={'Full Name'}
                                                                        type={"text"}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-md-5">
                                                        <Field
                                                            name={'emergency_contact_info.secondary_emergency.relationship'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikSelectDropdownComponent
                                                                        options={relationshipList}
                                                                        label={'Relationship'}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-md-2"></div>
                                                </div>
                                                <div className="ts-row">
                                                    <div className="ts-col-md-5">
                                                        <Field name={'emergency_contact_info.secondary_emergency.language'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikSelectDropdownComponent
                                                                        options={languageList}
                                                                        label={'Language'}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-md-5">
                                                    </div>
                                                    <div className="ts-col-md-2"></div>
                                                </div>
                                                <FormControlLabelComponent label={'Primary Phone'}/>
                                                <div className="ts-row">
                                                    <div className="ts-col-md-5">
                                                        <Field
                                                            name={'emergency_contact_info.secondary_emergency.primary_contact_info.phone_type'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikSelectDropdownComponent
                                                                        options={phoneTypeList}
                                                                        label={'Phone Type'}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-md-5">
                                                        <Field
                                                            name={'emergency_contact_info.secondary_emergency.primary_contact_info.phone'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikPhoneInputComponent
                                                                        label={'Phone Number'}
                                                                        placeholder={'Phone Number (Primary)'}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-md-2 d-flex align-items-center">
                                                        <IconButtonComponent className={"form-helper-icon"}>
                                                            <ToolTipComponent
                                                                showArrow={true}
                                                                position={"left"}
                                                                tooltip={"This phone number will be used to communicate with you in case of emergency. Ensure that this number is constantly operational."}>
                                                                <ImageConfig.InfoIcon/>
                                                            </ToolTipComponent>
                                                        </IconButtonComponent>
                                                    </div>
                                                </div>
                                                <FormControlLabelComponent label={'Alternate Phone'}/>
                                                <FieldArray
                                                    name="emergency_contact_info.secondary_emergency.secondary_contact_info"
                                                    render={(arrayHelpers) => (
                                                        <>
                                                            {values?.emergency_contact_info?.secondary_emergency?.secondary_contact_info && values?.emergency_contact_info?.secondary_emergency?.secondary_contact_info?.map((item: any, index: any) => {
                                                                return (
                                                                    <div className="ts-row" key={index}>
                                                                        <div className="ts-col-md-5">
                                                                            <Field
                                                                                name={`emergency_contact_info.secondary_emergency.secondary_contact_info[${index}].phone_type`}>
                                                                                {
                                                                                    (field: FieldProps) => (
                                                                                        <FormikSelectDropdownComponent
                                                                                            options={phoneTypeList}
                                                                                            label={'Phone Type'}
                                                                                            formikField={field}
                                                                                            fullWidth={true}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                        <div className="ts-col-md-5">
                                                                            <Field
                                                                                name={`emergency_contact_info.secondary_emergency.secondary_contact_info[${index}].phone`}>
                                                                                {
                                                                                    (field: FieldProps) => (
                                                                                        <FormikPhoneInputComponent
                                                                                            label={'Phone Number'}
                                                                                            placeholder={'Phone Number'}
                                                                                            formikField={field}
                                                                                            fullWidth={true}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                        <div className="ts-col-md-2 d-flex align-items-center">
                                                                            <IconButtonComponent className={"form-helper-icon"}
                                                                                                 onClick={() => {
                                                                                                     arrayHelpers.push({
                                                                                                         phone_type: undefined,
                                                                                                         phone: undefined
                                                                                                     });
                                                                                                 }}
                                                                            >
                                                                                <ImageConfig.AddCircleIcon/>
                                                                            </IconButtonComponent>
                                                                            {index > 0 &&
                                                                                <IconButtonComponent className={"form-helper-icon"}
                                                                                                     onClick={() => {
                                                                                                         arrayHelpers.remove(index);
                                                                                                     }}
                                                                                >
                                                                                    <ImageConfig.DeleteIcon/>
                                                                                </IconButtonComponent>}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </>
                                                    )}/>
                                            </>
                                        }
                                    </>
                                </div>
                                <div className="t-form-actions">
                                    <ButtonComponent
                                        id={"next_btn"}
                                        type={"submit"}
                                        suffixIcon={<ImageConfig.ArrowRightCircle/>}
                                        disabled={!isValid || isSubmitting}
                                        isLoading={isSubmitting}
                                    >
                                        Next
                                    </ButtonComponent>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );


};

export default ClientRegistrationEmergencyContactDetailsComponent;
