import {call, put, takeEvery} from "redux-saga/effects";
import {CommonService} from "../../shared/services";
import {
    GET_CLIENT_BASIC_DETAILS,
    GET_CLIENT_COMMUNICATION_REFERRAL_DETAILS,
    GET_CLIENT_MEDICAL_HISTORY_DETAILS,
    setClientBasicDetailsFailed,
    setClientCommunicationReferralDetailsFailed,
    setClientCommunicationReferralDetailsSuccess,
    setClientMedicalHistoryDetailsFailed,
    setClientMedicalHistoryDetailsSuccess
} from "../actions/client.action";

function* getClientBasicDetails() {
    try {
        // @ts-ignore
        const resp = yield call(CommonService._client.GetPersonalDetailsAPICall);
        yield put(setClientBasicDetailsFailed(resp?.data));
    } catch (error: any) {
        yield put(setClientBasicDetailsFailed(error));
    }
}

function* getClientCommunicationReferralDetails() {
    try {
        // @ts-ignore
        const resp = yield call(CommonService._client.GetClientCommunicationReferralDetailsAPICall);
        yield put(setClientCommunicationReferralDetailsSuccess(resp?.data));
    } catch (error: any) {
        yield put(setClientCommunicationReferralDetailsFailed(error));
    }
}

function* getClientMedicalHistoryDetails() {
    try {
        // @ts-ignore
        const resp = yield call(CommonService._client.GetClientMedicalHistoryDetailsAPICall);
        yield put(setClientMedicalHistoryDetailsSuccess(resp?.data));
    } catch (error: any) {
        yield put(setClientMedicalHistoryDetailsFailed(error));
    }
}

export default function* clientSaga() {
    yield takeEvery(GET_CLIENT_BASIC_DETAILS, getClientBasicDetails);
    yield takeEvery(GET_CLIENT_COMMUNICATION_REFERRAL_DETAILS, getClientCommunicationReferralDetails);
    yield takeEvery(GET_CLIENT_MEDICAL_HISTORY_DETAILS, getClientMedicalHistoryDetails);
}
