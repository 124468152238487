import "./ClientPersonalDetailsComponent.scss";
import * as Yup from "yup";
import {IClientPersonalDetails} from "../../../shared/models/client.model";
import {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import {ImageConfig, Misc, Patterns} from "../../../constants";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import FormikInputComponent from "../../../shared/components/form-controls/formik-input/FormikInputComponent";
import FormikDatePickerComponent
    from "../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent";
import FormikSelectDropdownComponent
    from "../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";
import moment from "moment";

interface ClientPersonalDetailsComponentProps {
    onPrevious?: () => void;
    onNext: () => void;
}

const ClientBasicDetailsFormValidationSchema = Yup.object({
    first_name: Yup.string()
        .required('First Name is required')
        .min(1, 'First Name must be at least 1 character')
        .max(100, 'First name cannot be greater than 100 characters')
    ,
    last_name: Yup.string()
        .required('Last Name is required')
        .min(1, 'Last Name must be at least 1 character')
        .max(100, 'Last name cannot be greater than 100 characters'),
    dob: Yup.mixed()
        .required('Date of Birth is required'),
    gender: Yup.mixed()
        .required('Gender is required'),
    nick_name: Yup.string()
        .nullable()
        .max(100, 'Nickname cannot be greater than 100 characters'),
    ssn: Yup.string()
        .required('SSN Number is required')
        .max(9, 'SSN Number cannot be greater than 9 characters')
});

const ClientBasicDetailsFormInitialValues: IClientPersonalDetails = {
    first_name: "",
    last_name: "",
    dob: "",
    gender: "",
    nick_name: "",
    ssn: ""
}

const ClientPersonalDetailsComponent = (props: ClientPersonalDetailsComponentProps) => {

    const {onNext} = props;
    const [clientBasicDetailsFormInitialValues, setClientBasicDetailsFormInitialValues] = useState<IClientPersonalDetails>(_.cloneDeep(ClientBasicDetailsFormInitialValues));
    const {
        genderList,
        isGenderListLoaded,
        isGenderListLoading
    } = useSelector((state: IRootReducerState) => state.staticData);

    const {
        clientBasicDetails,
        isClientBasicDetailsLoaded,
    } = useSelector((state: IRootReducerState) => state.client);

    useEffect(() => {
        if (isClientBasicDetailsLoaded) {
            setClientBasicDetailsFormInitialValues(_.cloneDeep(clientBasicDetails));
        }
    }, [clientBasicDetails, isClientBasicDetailsLoaded]);

    const onSubmit = useCallback((values: any, {setSubmitting, setErrors}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {...values, is_basic_details_updated: true};
        CommonService._client.ClientBasicDetailsSaveAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setSubmitting(false);
                onNext();
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                setSubmitting(false);
            })
    }, [onNext]);

    return (
        <div className={'client-personal-details-component'}>
            <Formik
                validationSchema={ClientBasicDetailsFormValidationSchema}
                initialValues={clientBasicDetailsFormInitialValues}
                validateOnChange={false}
                validateOnBlur={true}
                enableReinitialize={true}
                validateOnMount={true}
                onSubmit={onSubmit}
            >
                {({values, validateForm, isValid, isSubmitting}) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        validateForm();
                    }, [validateForm, values]);
                    return (
                        <Form className="t-form" noValidate={true}>
                            <div className="t-form-controls">
                                <div className="ts-row">
                                    <div className="ts-col-lg-6">
                                        <Field name={'first_name'} className="t-form-control">
                                            {
                                                (field: FieldProps) => (
                                                    <FormikInputComponent
                                                        id={'first_name_input'}
                                                        label={'First Name'}
                                                        placeholder={'Enter First Name'}
                                                        required={true}
                                                        formikField={field}
                                                        fullWidth={true}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div className="ts-col-lg-6">
                                        <Field name={'last_name'} className="t-form-control">
                                            {
                                                (field: FieldProps) => (
                                                    <FormikInputComponent
                                                        id={'last_name_input'}
                                                        label={'Last Name'}
                                                        placeholder={'Enter Last Name'}
                                                        required={true}
                                                        formikField={field}
                                                        fullWidth={true}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                </div>
                                <div className="ts-row">
                                    <div className="ts-col-lg-6">
                                        <Field name={'nick_name'} className="t-form-control">
                                            {
                                                (field: FieldProps) => (
                                                    <FormikInputComponent
                                                        id={'nick_name_input'}
                                                        label={'Nickname/Preferred Name'}
                                                        placeholder={'Enter Nickname/Preferred Name'}
                                                        formikField={field}
                                                        fullWidth={true}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div className="ts-col-lg-6">
                                        <Field name={'dob'} className="t-form-control">
                                            {
                                                (field: FieldProps) => (
                                                    <FormikDatePickerComponent
                                                        id={'dob_input'}
                                                        label={'Date Of Birth'}
                                                        placeholder={'Select Date of Birth'}
                                                        required={true}
                                                        formikField={field}
                                                        fullWidth={true}
                                                        maxDate={moment().subtract(18, 'years').toDate()}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                </div>
                                <div className="ts-row">
                                    <div className="ts-col-lg-6">
                                        <Field name={'gender'} className="t-form-control">
                                            {
                                                (field: FieldProps) => (
                                                    <FormikSelectDropdownComponent
                                                        id={'gender_dd'}
                                                        label={'Gender'}
                                                        options={genderList}
                                                        isDataLoading={isGenderListLoading}
                                                        isDataLoaded={isGenderListLoaded}
                                                        placeholder={'Select Gender'}
                                                        required={true}
                                                        formikField={field}
                                                        fullWidth={true}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div className="ts-col-lg-6">
                                        <Field name={'ssn'} className="t-form-control">
                                            {
                                                (field: FieldProps) => (
                                                    <FormikInputComponent
                                                        id={'ssn_input'}
                                                        label={'SSN (Social Security Number)'}
                                                        placeholder={'Enter SSN Number'}
                                                        required={true}
                                                        formikField={field}
                                                        fullWidth={true}
                                                        validationPattern={Patterns.POSITIVE_WHOLE_NUMBERS}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className="t-form-actions">
                                <ButtonComponent
                                    id={'next_btn'}
                                    type={"submit"}
                                    suffixIcon={<ImageConfig.ArrowRightCircle/>}
                                    disabled={!isValid || isSubmitting}
                                    isLoading={isSubmitting}
                                >
                                    Next
                                </ButtonComponent>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );

};

export default ClientPersonalDetailsComponent;
