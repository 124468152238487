import './TestScreen.scss';
import SignaturePadComponent from "../../shared/components/signature-pad/SignaturePadComponent";

const TestScreen = () => {

    return (
        <div className="test-screen">
            <SignaturePadComponent/>
        </div>
    );
};
export default TestScreen;
