import "./OnboardingScreen.scss";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {ImageConfig} from "../../../constants";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import {useState} from "react";
import ModalComponent from "../../../shared/components/modal/ModalComponent";
import IconButtonComponent from "../../../shared/components/icon-button/IconButtonComponent";
import {Link} from "react-router-dom";
import {CommonService} from "../../../shared/services";

interface OnboardingScreenProps {

}

const OnboardingScreen = (props: OnboardingScreenProps) => {

    const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] = useState<boolean>(false);

    return (
        <div className="on-boarding-screen">
            <div className="on-boarding-screen__header">
                <img src={ImageConfig.LogoLight} alt="logo"/>
            </div>
            <div className="on-boarding-screen__body">
                <div className="on-boarding-screen__body__row on-boarding-screen__body__title">
                    Welcome to the Client Portal Registration
                </div>
                <div className="on-boarding-screen__body__row on-boarding-screen__body__description">
                    Please complete these forms to simplify your check-in process.
                </div>
                <div className="on-boarding-screen__body__row">
                    Kindly enter and submit the requested information for each document. While the majority of the
                    fields are optional, some are mandatory and are denoted by an asterisk (*). Please avoid using the
                    back or forward buttons on your browser, as they may undo or redo your recent actions, resulting in
                    an error.
                </div>
                <div className="on-boarding-screen__body__row">
                    The information you submit is encrypted for your protection, kept confidential, and will be sent
                    securely to our facility.
                </div>
                <div className="on-boarding-screen__body__row">
                    If you have any questions or concerns at any time, please do not hesitate to contact us.
                </div>
                <div className="on-boarding-screen__body__row">
                    <ImageConfig.CallIcon color={"#83909D"}/>&nbsp;&nbsp;(000)-000-0000
                </div>
                <div className="on-boarding-screen__body__row">
                    Please click the following link to view our Notice of&nbsp;&nbsp;<LinkComponent
                    onClick={() => setIsPrivacyPolicyModalOpen(true)}
                >
                   <span id={'privacy_link'}>Privacy Practices.</span> 
                </LinkComponent>
                </div>
                <div className="on-boarding-screen__footer">
                    <Link  to={CommonService._routeConfig.ClientRegistration()}>
                        <ButtonComponent
                            id={'next_btn'}
                            suffixIcon={<ImageConfig.ArrowRightCircle/>}
                        >
                            Next
                        </ButtonComponent>
                    </Link>
                </div>
            </div>
            <ModalComponent isOpen={isPrivacyPolicyModalOpen}
                            className="privacy-policy-modal"
                            onClose={() => setIsPrivacyPolicyModalOpen(false)}>
                <div className="privacy-policy-modal__content">
                    <div className="privacy-policy-modal__content_left">
                        <div className="privacy-policy-modal__content_icon">
                            <ImageConfig.PrivacyPolicyIcon/>
                        </div>
                        <div className="privacy-policy-modal__content_title">
                            Notice of Privacy<br/>
                            Practices
                        </div>
                    </div>
                    <div className="privacy-policy-modal__content_right">
                        <div className="privacy-policy-modal__content_right__header">
                     
                            <IconButtonComponent id={'close_btn'} onClick={
                                () => setIsPrivacyPolicyModalOpen(false)
                            }>
                                <ImageConfig.CloseIcon/>
                            </IconButtonComponent>
                        </div>
                        <div className="privacy-policy-modal__content_right__block">
                            <div className="privacy-policy-modal__content_right__block__title">
                                Our Notice of Privacy Practices
                            </div>
                            <div className="privacy-policy-modal__content_right__block__content">
                                At Kinergy Sports Medicine and Performance, we respect and are committed to the privacy
                                and confidentiality of the personal and health information for all our clients. As an
                                essential part of our commitment to you, our use and disclosure of certain confidential
                                personal or health care information about you may be subject to the requirements of the
                                Health Insurance Portability and Accountability Act of 1996 (HIPAA) and applicable state
                                law. Any information that you submit to us that constitutes Protected Health Information
                                (PHI), as defined by HIPAA, is subject to HIPAA and applicable state law. The term PHI
                                refers to individually identifiable health information about your past, present or
                                future physical or mental health or condition, the provision of health care to you or
                                the past, present or future payment for such care. If any information collected by
                                Kinergy Sports Medicine and Performance constitutes PHI, then our Notice of Privacy
                                Practices will apply.
                                <br/> <br/>
                                By way of my signature, I am stating I have read the Notice of Privacy Practices for
                                Kinergy Sports Medicine and Performance, and understand my rights contained in the
                                Notice.
                                <br/> <br/>
                                Additionally, by way of my signature, I authorize and consent to the use and disclosure
                                of my protected health information for the purposes described in the Notice.
                            </div>
                        </div>
                    </div>
                </div>
            </ModalComponent>

        </div>
    );

};

export default OnboardingScreen;
