import {IActionModel} from "../../shared/models/action.model";
import {
    GET_CLIENT_BASIC_DETAILS,
    GET_CLIENT_COMMUNICATION_REFERRAL_DETAILS,
    GET_CLIENT_MEDICAL_HISTORY_DETAILS,
    SET_CLIENT_BASIC_DETAILS_FAILED,
    SET_CLIENT_BASIC_DETAILS_SUCCESS,
    SET_CLIENT_COMMUNICATION_REFERRAL_DETAILS_FAILED,
    SET_CLIENT_COMMUNICATION_REFERRAL_DETAILS_SUCCESS,
    SET_CLIENT_MEDICAL_HISTORY_DETAILS_FAILED,
    SET_CLIENT_MEDICAL_HISTORY_DETAILS_SUCCESS
} from "../actions/client.action";

export interface IClientReducerState {
    isClientBasicDetailsLoaded?: boolean;
    isClientBasicDetailsLoading?: boolean;
    isClientBasicDetailsLoadingFailed?: boolean;
    clientBasicDetails?: any;
    clientBasicDetailsError?: any;
    isClientCommunicationReferralDetailsLoaded?: boolean;
    isClientCommunicationReferralDetailsLoading?: boolean;
    isClientCommunicationReferralDetailsLoadingFailed?: boolean;
    clientCommunicationReferralDetails?: any;
    clientCommunicationReferralDetailsError?: any;
    isClientMedicalHistoryDetailsLoaded?: boolean;
    isClientMedicalHistoryDetailsLoading?: boolean;
    isClientMedicalHistoryDetailsLoadingFailed?: boolean;
    clientMedicalHistoryDetails?: any;
    clientMedicalHistoryDetailsError?: any;
}

const INITIAL_STATE: IClientReducerState = {
    clientBasicDetails: undefined,
    clientCommunicationReferralDetails: undefined,
};

const clientReducer = (state: IClientReducerState = INITIAL_STATE, action: IActionModel): IClientReducerState => {
    switch (action.type) {
        case GET_CLIENT_BASIC_DETAILS:
            return {
                ...state,
                isClientBasicDetailsLoaded: false,
                isClientBasicDetailsLoading: true,
                isClientBasicDetailsLoadingFailed: false
            };
        case SET_CLIENT_BASIC_DETAILS_SUCCESS:
            return {
                ...state,
                isClientBasicDetailsLoaded: true,
                isClientBasicDetailsLoading: false,
                isClientBasicDetailsLoadingFailed: false,
                clientBasicDetailsError: undefined,
                clientBasicDetails: action.payload
            }
        case SET_CLIENT_BASIC_DETAILS_FAILED:
            return {
                ...state,
                isClientBasicDetailsLoaded: false,
                isClientBasicDetailsLoading: false,
                isClientBasicDetailsLoadingFailed: true,
                clientBasicDetailsError: action.payload
            }
        case GET_CLIENT_COMMUNICATION_REFERRAL_DETAILS:
            return {
                ...state,
                isClientCommunicationReferralDetailsLoaded: false,
                isClientCommunicationReferralDetailsLoading: true,
                isClientCommunicationReferralDetailsLoadingFailed: false
            };
        case SET_CLIENT_COMMUNICATION_REFERRAL_DETAILS_SUCCESS:
            return {
                ...state,
                isClientCommunicationReferralDetailsLoaded: true,
                isClientCommunicationReferralDetailsLoading: false,
                isClientCommunicationReferralDetailsLoadingFailed: false,
                clientCommunicationReferralDetailsError: undefined,
                clientCommunicationReferralDetails: action.payload
            }
        case SET_CLIENT_COMMUNICATION_REFERRAL_DETAILS_FAILED:
            return {
                ...state,
                isClientCommunicationReferralDetailsLoaded: false,
                isClientCommunicationReferralDetailsLoading: false,
                isClientCommunicationReferralDetailsLoadingFailed: true,
                clientCommunicationReferralDetailsError: action.payload
            }
        case GET_CLIENT_MEDICAL_HISTORY_DETAILS:
            return {
                ...state,
                isClientMedicalHistoryDetailsLoaded: false,
                isClientMedicalHistoryDetailsLoading: true,
                isClientMedicalHistoryDetailsLoadingFailed: false
            };
        case SET_CLIENT_MEDICAL_HISTORY_DETAILS_SUCCESS:
            return {
                ...state,
                isClientMedicalHistoryDetailsLoaded: true,
                isClientMedicalHistoryDetailsLoading: false,
                isClientMedicalHistoryDetailsLoadingFailed: false,
                clientMedicalHistoryDetailsError: undefined,
                clientMedicalHistoryDetails: action.payload
            }
        case SET_CLIENT_MEDICAL_HISTORY_DETAILS_FAILED:
            return {
                ...state,
                isClientMedicalHistoryDetailsLoaded: false,
                isClientMedicalHistoryDetailsLoading: false,
                isClientMedicalHistoryDetailsLoadingFailed: true,
                clientMedicalHistoryDetailsError: action.payload
            }
        default:
            return state;
    }
};

export default clientReducer;

