import "./ClientRegistrationMedicalHistoryMusculoskeletalHistoryComponent.scss";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "../../store/reducers";
import _ from "lodash";
import * as Yup from "yup";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import React, {useCallback, useEffect, useState} from "react";
import {CommonService} from "../../shared/services";
import {IAPIResponseType} from "../../shared/models/api.model";
import FormikRadioButtonGroupComponent
    from "../../shared/components/form-controls/formik-radio-button/FormikRadioButtonComponent";
import FormikTextAreaComponent from "../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent";
import {IMusculoskeletalHistoryOption} from "../../shared/models/common.model";
import {ImageConfig, Misc} from "../../constants";
import ButtonComponent from "../../shared/components/button/ButtonComponent";
import FormControlLabelComponent from "../../shared/components/form-control-label/FormControlLabelComponent";
import {getClientMedicalHistoryDetails} from "../../store/actions/client.action";
import FormDebuggerComponent from "../../shared/components/form-debugger/FormDebuggerComponent";

interface ClientRegistrationMedicalHistoryMusculoskeletalHistoryComponentProps {
    onNext: () => void;
}

const ClientMusculoskeletalHistoryFormInitialValues: any = {
    "musculoskeletal_history": {}
};

const ClientRegistrationMedicalHistoryMusculoskeletalHistoryComponent = (props: ClientRegistrationMedicalHistoryMusculoskeletalHistoryComponentProps) => {

    const {onNext} = props;
    const dispatch = useDispatch();
    const [clientMusculoskeletalHistoryFormInitialValues, setClientMusculoskeletalHistoryFormInitialValues] = useState<any>(_.cloneDeep(ClientMusculoskeletalHistoryFormInitialValues));
    const {musculoskeletalHistoryOptionsList} = useSelector((state: IRootReducerState) => state.staticData);

    const [clientMusculoskeletalHistoryFormValidationSchema, setClientMusculoskeletalHistoryFormValidationSchema] = useState<any>(Yup.object({
        musculoskeletal_history: Yup.object({})
    }));

    const {
        clientMedicalHistoryDetails,
    } = useSelector((state: IRootReducerState) => state.client);

    useEffect(() => {
        if (clientMedicalHistoryDetails) {
            if (clientMedicalHistoryDetails?.musculoskeletal_history?.comments) {
                clientMedicalHistoryDetails.musculoskeletal_history.isCustomOption = true;
            }
            setClientMusculoskeletalHistoryFormInitialValues({
                musculoskeletal_history: clientMedicalHistoryDetails.musculoskeletal_history || []
            });
        }
    }, [clientMedicalHistoryDetails]);

    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        const payload = {...values};
        setSubmitting(true);
        CommonService._client.ClientMedicalHistoryMusculoskeletalHistoryDetailsSaveAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setSubmitting(false);
                dispatch(getClientMedicalHistoryDetails());
                onNext();
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                setSubmitting(false);
            });
    }, [dispatch, onNext]);

    useEffect(() => {
        console.log(musculoskeletalHistoryOptionsList);
        if (musculoskeletalHistoryOptionsList?.length > 0) {
            const musculoskeletal_history: any = {};
            musculoskeletalHistoryOptionsList?.forEach((option: IMusculoskeletalHistoryOption) => {
                    musculoskeletal_history[option._id] = Yup.object({
                        value: Yup.boolean().required(),
                        text: Yup.string().when("value", {
                            is: (value: boolean) => value,
                            then: Yup.string().required('Information is required'),
                            otherwise: Yup.string().nullable(),
                        }),
                });
            });
            const validationSchema = Yup.object({
                musculoskeletal_history: Yup.object(musculoskeletal_history)
            });
            setClientMusculoskeletalHistoryFormValidationSchema(validationSchema);
        }
    }, [musculoskeletalHistoryOptionsList]);

    return (
        <div className={'client-registration-medical-history-musculoskeletal-history-component'}>
            <FormControlLabelComponent label={'Have you ever:'}/>
            <Formik
                validationSchema={clientMusculoskeletalHistoryFormValidationSchema}
                initialValues={clientMusculoskeletalHistoryFormInitialValues}
                onSubmit={onSubmit}
                validateOnChange={false}
                validateOnBlur={true}
                enableReinitialize={true}
                validateOnMount={true}>
                {({values, setFieldValue, isSubmitting, errors, isValid, validateForm}) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        validateForm();
                    }, [validateForm, values]);
                    return (
                        <Form noValidate={true} className={"t-form"}>
                            <FormDebuggerComponent errors={errors} values={values} showDebugger={false}/>
                            <div className="t-form-controls">
                                {
                                    musculoskeletalHistoryOptionsList?.map((question: IMusculoskeletalHistoryOption) => {
                                        const {_id, title} = question;
                                        return <div key={_id}>
                                            <div className="ts-row mrg-top-10 mrg-bottom-10">
                                                <div className="ts-col-md-8">
                                                    {title} *
                                                </div>
                                                <div className="ts-col-md-4">
                                                    <Field name={`musculoskeletal_history.${_id}.value`}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikRadioButtonGroupComponent
                                                                    options={CommonService._staticData.yesNoOptions}
                                                                    displayWith={(option) => option.title}
                                                                    valueExtractor={(option) => option.code}
                                                                    required={true}
                                                                    formikField={field}
                                                                    id={"musculos"}
                                                                    onChange={(value) => {
                                                                        setFieldValue(`musculoskeletal_history.${_id}.text`, undefined);
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="ts-row mrg-top-10 mrg-bottom-10"
                                                 key={_id}>
                                                <div className={"ts-col-md-12"}>
                                                    <Field name={`musculoskeletal_history.${_id}.text`}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikTextAreaComponent
                                                                    id={"musculos_input"}
                                                                    placeholder={"Please provide pertinent information here (date of injury, date of surgery, side of body, injury type, etc)."}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="t-form-actions">
                                <ButtonComponent
                                    type={"submit"}
                                    isLoading={isSubmitting}
                                    disabled={isSubmitting || !isValid}
                                    suffixIcon={<ImageConfig.ArrowRightCircle/>}
                                >
                                    Next
                                </ButtonComponent>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );

};

export default ClientRegistrationMedicalHistoryMusculoskeletalHistoryComponent;
