import "./DesignSystemScreen.scss";
import * as Yup from "yup";
import React, {useCallback, useEffect, useState} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from 'formik';
import FormikInputComponent from "../../shared/components/form-controls/formik-input/FormikInputComponent";
import CardComponent from "../../shared/components/card/CardComponent";
import ButtonComponent from "../../shared/components/button/ButtonComponent";
import {ImageConfig} from "../../constants";
import FormikCheckBoxComponent from "../../shared/components/form-controls/formik-check-box/FormikCheckBoxComponent";
import FormikPhoneInputComponent from "../../shared/components/formik-phone-input/FormikPhoneInputComponent";
import FormDebuggerComponent from "../../shared/components/form-debugger/FormDebuggerComponent";
import FormikDatePickerComponent
    from "../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent";
import _ from "lodash";
import FormikSelectDropdownComponent
    from "../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
import {CommonService} from "../../shared/services";

interface DesignSystemScreenProps {

}

const designSystemFormValidationSchema = Yup.object({
    name: Yup.string()
        .required("Name is required"),
    email: Yup.string()
        .required("Email is required"),
    phone: Yup.string()
        .required("Phone Number is required"),
    otp: Yup.string()
        .required("OTP is required")
        .min(6, "OTP should be 6 digits")
        .max(6, "OTP should be 6 digits"),
    dob: Yup.mixed()
        .required("Date Of Birth is required"),
    isAdmin: Yup.mixed()
        .required("Is Admin is required"),
    gender: Yup.string()
        .required('Gender is required'),
    about: Yup.string()
        .required('About is required'),
    isTnCAgreed: Yup.boolean()
        .required("Terms and Conditions acceptance agreement is required"),
});

const DesignSystemInitialValues = {
    name: "",
    email: "",
    phone: "",
    otp: "",
    dob: "",
    role: "",
    about: "",
    gender: "",
    isAdmin: true,
    isTnCAgreed: false
};

const DesignSystemScreen = (props: DesignSystemScreenProps) => {

    const [designSystemFormInitialValues] = useState<any>(_.cloneDeep(DesignSystemInitialValues));

    const onSubmit = useCallback((values: any, {setSubmitting}: FormikHelpers<any>) => {
        console.log(values);
        setSubmitting(true);
        setTimeout(() => {
            setSubmitting(false);
        }, 500);
    }, []);

    return (
        <div className="design-system-screen screen">
            <div className="design-system-form-container">
                <CardComponent title={"Kinergy Customer Design System"}>
                    <Formik
                        validationSchema={designSystemFormValidationSchema}
                        initialValues={designSystemFormInitialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onSubmit}
                    >
                        {({values, isSubmitting, resetForm, isValid, validateForm, errors}) => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form className={"t-form"} noValidate={true}>
                                    <FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>
                                    <Field name={'name'} className="t-form-control">
                                        {
                                            (field: FieldProps) => (
                                                <FormikInputComponent
                                                    label={'Name'}
                                                    placeholder={'Enter Name'}
                                                    type={"text"}
                                                    required={true}
                                                    formikField={field}
                                                    fullWidth={true}
                                                />
                                            )
                                        }
                                    </Field>
                                    <Field name={'email'} className="t-form-control">
                                        {
                                            (field: FieldProps) => (
                                                <FormikInputComponent
                                                    label={'Email'}
                                                    placeholder={'Enter Email'}
                                                    type={"email"}
                                                    required={true}
                                                    formikField={field}
                                                    fullWidth={true}
                                                />
                                            )
                                        }
                                    </Field>
                                    <Field name={'phone'} className="t-form-control">
                                        {
                                            (field: FieldProps) => (
                                                <FormikPhoneInputComponent
                                                    label={'Phone Number'}
                                                    formikField={field}
                                                    required={true}
                                                    fullWidth={true}
                                                />
                                            )
                                        }
                                    </Field>
                                    <Field name={'dob'} className="t-form-control">
                                        {
                                            (field: FieldProps) => (
                                                <FormikDatePickerComponent
                                                    label={'Date Of Birth'}
                                                    formikField={field}
                                                    required={true}
                                                    fullWidth={true}
                                                />
                                            )
                                        }
                                    </Field>
                                    {/*<Field name={'otp'} className="t-form-control">*/}
                                    {/*    {*/}
                                    {/*        (field: FieldProps) => (*/}
                                    {/*            <FormikOTPComponent*/}
                                    {/*                label={'OTP'}*/}
                                    {/*                formikField={field}*/}
                                    {/*                required={true}*/}
                                    {/*                fullWidth={true}*/}
                                    {/*            />*/}
                                    {/*        )*/}
                                    {/*    }*/}
                                    {/*</Field>*/}
                                    {/*<Field name={'about'} className="t-form-control">*/}
                                    {/*    {*/}
                                    {/*        (field: FieldProps) => (*/}
                                    {/*            <FormikTextAreaComponent*/}
                                    {/*                label={'About'}*/}
                                    {/*                formikField={field}*/}
                                    {/*                required={true}*/}
                                    {/*                fullWidth={true}*/}
                                    {/*            />*/}
                                    {/*        )*/}
                                    {/*    }*/}
                                    {/*</Field>*/}
                                    <Field name={'isAdmin'} className="t-form-control">
                                        {
                                            (field: FieldProps) => (
                                                <FormikSelectDropdownComponent
                                                    label={'Is Admin'}
                                                    options={CommonService._staticData.yesNoOptions}
                                                    formikField={field}
                                                    searchable={false}
                                                    required={true}
                                                    fullWidth={true}
                                                    displayWith={(option: any) => option.title}
                                                    valueExtractor={(option: any) => option.code}
                                                />
                                            )
                                        }
                                    </Field>
                                    {/*<Field name={'gender'} className="t-form-control">*/}
                                    {/*    {*/}
                                    {/*        (field: FieldProps) => (*/}
                                    {/*            <FormikRadioButtonGroupComponent*/}
                                    {/*                label={'Gender'}*/}
                                    {/*                options={*/}
                                    {/*                    [*/}
                                    {/*                        {*/}
                                    {/*                            code: "male",*/}
                                    {/*                            title: "Male"*/}
                                    {/*                        },*/}
                                    {/*                        {*/}
                                    {/*                            code: "female",*/}
                                    {/*                            title: "Female"*/}
                                    {/*                        }*/}
                                    {/*                    ]*/}
                                    {/*                }*/}
                                    {/*                formikField={field}*/}
                                    {/*                required={true}*/}
                                    {/*            />*/}
                                    {/*        )*/}
                                    {/*    }*/}
                                    {/*</Field>*/}
                                    <Field name={'isTnCAgreed'} className="t-form-control">
                                        {
                                            (field: FieldProps) => (
                                                <FormikCheckBoxComponent
                                                    label={'I accept to TnC'}
                                                    required={true}
                                                    formikField={field}
                                                />
                                            )
                                        }
                                    </Field>
                                    <div className="t-form-actions">
                                        <ButtonComponent type={"button"}
                                                         fullWidth={true}
                                                         variant={"outlined"}
                                                         onClick={() => {
                                                             resetForm();
                                                         }}
                                        >
                                            Clear
                                        </ButtonComponent>&nbsp;
                                        <ButtonComponent type={"submit"}
                                                         fullWidth={true}
                                                         isLoading={isSubmitting}
                                            // disabled={!isValid}
                                                         suffixIcon={<ImageConfig.ArrowRightCircle/>}
                                        >
                                            Submit
                                        </ButtonComponent>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </CardComponent>
            </div>
        </div>
    );
};


export default DesignSystemScreen;
