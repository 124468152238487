export const GET_CLIENT_BASIC_DETAILS = "GET_CLIENT_BASIC_DETAILS";
export const SET_CLIENT_BASIC_DETAILS_SUCCESS = "SET_CLIENT_BASIC_DETAILS_SUCCESS";
export const SET_CLIENT_BASIC_DETAILS_FAILED = "SET_CLIENT_BASIC_DETAILS_FAILED";

export const GET_CLIENT_COMMUNICATION_REFERRAL_DETAILS = "GET_CLIENT_COMMUNICATION_REFERRAL_DETAILS";
export const SET_CLIENT_COMMUNICATION_REFERRAL_DETAILS_SUCCESS = "SET_CLIENT_COMMUNICATION_REFERRAL_DETAILS_SUCCESS";
export const SET_CLIENT_COMMUNICATION_REFERRAL_DETAILS_FAILED = "SET_CLIENT_COMMUNICATION_REFERRAL_DETAILS_FAILED";

export const GET_CLIENT_MEDICAL_HISTORY_DETAILS = "GET_CLIENT_MEDICAL_HISTORY_DETAILS";
export const SET_CLIENT_MEDICAL_HISTORY_DETAILS_SUCCESS = "SET_CLIENT_MEDICAL_HISTORY_DETAILS_SUCCESS";
export const SET_CLIENT_MEDICAL_HISTORY_DETAILS_FAILED = "SET_CLIENT_MEDICAL_HISTORY_DETAILS_FAILED";


export const getClientBasicDetails = () => {
    return {
        type: GET_CLIENT_BASIC_DETAILS
    }
};

export const setClientBasicDetailsSuccess = (clientBasicDetails: any) => {
    return {
        type: SET_CLIENT_BASIC_DETAILS_SUCCESS,
        payload: clientBasicDetails
    }
};

export const setClientBasicDetailsFailed = (error: any) => {
    return {
        type: SET_CLIENT_BASIC_DETAILS_SUCCESS,
        payload: error
    }
};

export const getClientCommunicationReferralDetails = () => {
    return {
        type: GET_CLIENT_COMMUNICATION_REFERRAL_DETAILS
    }
};

export const setClientCommunicationReferralDetailsSuccess = (clientCommunicationReferralDetails: any) => {
    return {
        type: SET_CLIENT_COMMUNICATION_REFERRAL_DETAILS_SUCCESS,
        payload: clientCommunicationReferralDetails
    }
};

export const setClientCommunicationReferralDetailsFailed = (error: any) => {
    return {
        type: SET_CLIENT_COMMUNICATION_REFERRAL_DETAILS_FAILED,
        payload: error
    }
};

export const getClientMedicalHistoryDetails = () => {
    return {
        type: GET_CLIENT_MEDICAL_HISTORY_DETAILS
    }
};

export const setClientMedicalHistoryDetailsSuccess = (clientMedicalHistoryDetails: any) => {
    return {
        type: SET_CLIENT_MEDICAL_HISTORY_DETAILS_SUCCESS,
        payload: clientMedicalHistoryDetails
    }
};

export const setClientMedicalHistoryDetailsFailed = (error: any) => {
    return {
        type: SET_CLIENT_MEDICAL_HISTORY_DETAILS_FAILED,
        payload: error
    }
};
