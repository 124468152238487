import {IActionModel} from "../../shared/models/action.model";
import {ClientRegistrationStepKeyType} from "../../shared/models/client.model";
import {SET_CLIENT_REGISTRATION_STEP, SET_CLIENT_REGISTRATION_SUB_STEP} from "../actions/state-management.action";

export interface IStateManagementReducerState {
    clientRegistrationStep?: ClientRegistrationStepKeyType;
    clientRegistrationSubStep?: any;
}

const INITIAL_STATE: IStateManagementReducerState = {
    clientRegistrationStep: undefined,
    clientRegistrationSubStep: undefined,
};

const stateManagementReducer = (state: IStateManagementReducerState = INITIAL_STATE, action: IActionModel): IStateManagementReducerState => {
    switch (action.type) {
        case SET_CLIENT_REGISTRATION_STEP:
            // CommonService.setQueryParam('step', action.payload);
            return {
                ...state,
                clientRegistrationStep: action.payload
            };
        case SET_CLIENT_REGISTRATION_SUB_STEP:
            // CommonService.setQueryParam('subStep', action.payload);
            return {
                ...state,
                clientRegistrationSubStep: action.payload
            }
        default:
            return state;
    }
};

export default stateManagementReducer;

