import React from "react";
import {Outlet} from "react-router-dom";

export interface AppLayoutProps {

}

const AppLayout = (props: AppLayoutProps) => {

    return (
        <div className="app-layout">
            <Outlet/>
        </div>
    );
}


export default AppLayout;




