import "./ClientRegistrationMedicalHistoryQuestionnaireComponent.scss";
import {useCallback, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import ClientRegistrationMedicalHistoryPersonalHabitsComponent
    from "../client-registration-medical-history-personal-habits/ClientRegistrationMedicalHistoryPersonalHabitsComponent";
import ClientRegistrationMedicalHistoryMedicationsSupplimentsComponent
    from "../client-registration-medical-history-medications-suppliments/ClientRegistrationMedicalHistoryMedicationsSupplimentsComponent";
import ClientRegistrationMedicalHistoryAllergiesComponent
    from "../client-registration-medical-history-allergies/ClientRegistrationMedicalHistoryAllergiesComponent";
import ClientRegistrationMedicalHistoryMedicalHistoryComponent
    from "../client-registration-medical-history-medical-history/ClientRegistrationMedicalHistoryMedicalHistoryComponent";
import ClientRegistrationMedicalHistorySurgicalHistoryComponent
    from "../client-registration-medical-history-surgical-history/ClientRegistrationMedicalHistorySurgicalHistoryComponent";
import ClientRegistrationMedicalHistoryMusculoskeletalHistoryComponent
    from "../client-registration-medical-history-musculoskeletal-history/ClientRegistrationMedicalHistoryMusculoskeletalHistoryComponent";
import ClientRegistrationMedicalHistoryFemalesOnlyComponent
    from "../client-registration-medical-history-females-only/ClientRegistrationMedicalHistoryFemalesOnlyComponent";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "../../store/reducers";
import {setClientRegistrationSubStep} from "../../store/actions/state-management.action";
import {getClientMedicalHistoryDetails} from "../../store/actions/client.action";

interface ClientRegistrationMedicalHistoryQuestionnaireComponentProps {
    onNext: () => void;
}

type ClientMedicalHistoryQuestionnaireDetailsType =
    'personal-habits'
    | 'medication-supplements'
    | 'allergies'
    | 'medical-history'
    | 'female-only'
    | 'surgical-history'
    | 'musculoskeletal-history';

const ClientMedicalHistoryQuestionnaireDetailsKeyMapping: {
    [key in ClientMedicalHistoryQuestionnaireDetailsType]: string
} = {
    'personal-habits': 'Personal Habits',
    'medication-supplements': 'Medications/Supplements',
    "medical-history": "Medical History",
    "allergies": "Allergies",
    'female-only': "Females Only",
    'surgical-history': "Surgical History",
    'musculoskeletal-history': "Musculoskeletal History"
}

const ClientRegistrationMedicalHistoryQuestionnaireDetailsSteps: ClientMedicalHistoryQuestionnaireDetailsType[] = ['personal-habits', 'medication-supplements', 'allergies', 'medical-history', 'female-only', 'surgical-history', 'musculoskeletal-history'];
const DEFAULT_SUB_STEP_KEY: ClientMedicalHistoryQuestionnaireDetailsType = 'personal-habits';

const ClientRegistrationMedicalHistoryQuestionnaireComponent = (props: ClientRegistrationMedicalHistoryQuestionnaireComponentProps) => {

    const {onNext} = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentStep, setCurrentStep] = useState<ClientMedicalHistoryQuestionnaireDetailsType>(DEFAULT_SUB_STEP_KEY);
    const dispatch = useDispatch();

    const {
        clientRegistrationSubStep
    } = useSelector((state: IRootReducerState) => state.stateManagement);

    const {
        clientBasicDetails,
    } = useSelector((state: IRootReducerState) => state.client);


    useEffect(() => {
        const subStep: ClientMedicalHistoryQuestionnaireDetailsType = searchParams.get("subStep") as ClientMedicalHistoryQuestionnaireDetailsType;
        if (ClientRegistrationMedicalHistoryQuestionnaireDetailsSteps.includes(subStep)) {
            setCurrentStep(subStep as ClientMedicalHistoryQuestionnaireDetailsType);
            dispatch(setClientRegistrationSubStep(subStep));
        } else {
            searchParams.set("subStep", DEFAULT_SUB_STEP_KEY);
            setSearchParams(searchParams);
            setCurrentStep(DEFAULT_SUB_STEP_KEY);
            dispatch(setClientRegistrationSubStep(DEFAULT_SUB_STEP_KEY));
        }
    }, [dispatch, searchParams, setSearchParams]);

    const handleNext = useCallback(() => {
        const nextStepIndex = ClientRegistrationMedicalHistoryQuestionnaireDetailsSteps.indexOf(currentStep) + 1;
        if (nextStepIndex < ClientRegistrationMedicalHistoryQuestionnaireDetailsSteps.length) {
            let nextStep = ClientRegistrationMedicalHistoryQuestionnaireDetailsSteps[nextStepIndex];
            if (nextStep === 'female-only') {
                if (clientBasicDetails?.gender !== 'female') {
                    nextStep = 'surgical-history';
                }
            }
            searchParams.set("subStep", nextStep);
            setSearchParams(searchParams);
            setCurrentStep(nextStep);
            dispatch(setClientRegistrationSubStep(nextStep));
            dispatch(getClientMedicalHistoryDetails());
        } else {
            onNext();
        }
    }, [dispatch, onNext, clientBasicDetails, currentStep, searchParams, setSearchParams]);

    useEffect(() => {
        if (clientRegistrationSubStep) {
            setCurrentStep(clientRegistrationSubStep);
        }
    }, [clientRegistrationSubStep]);

    return (
        <div className="client-registration-details-block client-registration-medical-history-questionnaire-component">
            <div className="client-registration-details-block__header">
                <div className="client-registration-details-block__title">
                    {ClientMedicalHistoryQuestionnaireDetailsKeyMapping[currentStep]}
                </div>
            </div>
            <div className="client-registration-details-block__body">
                {
                    currentStep === 'personal-habits' &&
                    <ClientRegistrationMedicalHistoryPersonalHabitsComponent onNext={handleNext}/>
                }
                {
                    currentStep === 'medication-supplements' &&
                    <ClientRegistrationMedicalHistoryMedicationsSupplimentsComponent onNext={handleNext}/>
                }
                {
                    currentStep === 'allergies' &&
                    <ClientRegistrationMedicalHistoryAllergiesComponent onNext={handleNext}/>
                }
                {
                    currentStep === 'medical-history' &&
                    <ClientRegistrationMedicalHistoryMedicalHistoryComponent onNext={handleNext}/>
                }
                {currentStep === 'female-only' &&
                    <ClientRegistrationMedicalHistoryFemalesOnlyComponent onNext={handleNext}/>
                }
                {
                    currentStep === 'surgical-history' &&
                    <ClientRegistrationMedicalHistorySurgicalHistoryComponent onNext={handleNext}/>
                }
                {
                    currentStep === 'musculoskeletal-history' &&
                    <ClientRegistrationMedicalHistoryMusculoskeletalHistoryComponent onNext={handleNext}/>
                }
            </div>
        </div>
    );

};

export default ClientRegistrationMedicalHistoryQuestionnaireComponent;
