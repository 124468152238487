import "./SearchComponent.scss";
import InputComponent from "../form-controls/input/InputComponent";
import {useCallback, useEffect, useState} from "react";

interface SearchComponentProps{
    label?: string;
    size?: 'small' | 'medium';
    className?:any;
    placeholder?: string;
    value?: string;
    onChange?: (value: any) => void;
}

const SearchComponent = (props: SearchComponentProps) => {

    const {label,className, onChange} = props;
    const [searchText, setSearchText] = useState<string | undefined>(props.value);
    const placeholder = props.placeholder || 'Search';
    const size = props.size || 'small';

    useEffect(() => {
        setSearchText(props.value);
    }, [props.value]);

    const handleSearchTextChange = useCallback((value: any) => {
        if (onChange) {
            onChange(value);
        }
        setSearchText(value);
    }, [onChange]);
    //
    // const handleSearchClear = useCallback(() => {
    //     handleSearchTextChange("");
    // }, [handleSearchTextChange]);

    return (
        <div className={'search-component'}>
            <InputComponent
                label={label}
                className={className}
                value={searchText}
                onChange={handleSearchTextChange}
                size={size}
                fullWidth={true}
                placeholder={placeholder}
            />
        </div>
    );

};

export default SearchComponent;
