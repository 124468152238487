import "./LoginScreen.scss";
import * as Yup from "yup";
import {ImageConfig, Misc} from "../../../constants";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {useDispatch} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import _ from "lodash";
import FormikPhoneInputComponent from "../../../shared/components/formik-phone-input/FormikPhoneInputComponent";
import FormikOTPComponent from "../../../shared/components/form-controls/formik-otp/FormikOtpComponent";
import {IAPIResponseType} from "../../../shared/models/api.model";
import {CommonService} from "../../../shared/services";
import FormikInputComponent from "../../../shared/components/form-controls/formik-input/FormikInputComponent";
import {useNavigate} from "react-router-dom";
import IconButtonComponent from "../../../shared/components/icon-button/IconButtonComponent";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import {setLoggedInUserData, setLoggedInUserToken} from "../../../store/actions/account.action";

const loginFormValidationSchema = Yup.object({
    login_mode: Yup.string(),
    username: Yup.string()
        .when("login_mode", {
            is: (login_mode: 'email' | 'phone') => login_mode === 'email',
            then: Yup.string().required('Email is required').email('Username must be a valid email'),
            otherwise: Yup.string().required('Phone Number is required'),
        }),
});

const otpFormValidationSchema = Yup.object({
    login_mode: Yup.string(),
    username: Yup.string().required('Username is required'),
    otp: Yup.string().required('OTP is required').min(6, 'OTP must be 6 digits'),
});

interface LoginScreenProps {

}

const LoginFormInitialValues = {
    login_mode: "email",
    username: ""
}

const OTPFormInitialValues = {
    login_mode: "email",
    username: "",
    otp: ""
}

const OTP_VALIDITY = 60;

const LoginScreen = (props: LoginScreenProps) => {

    const [currentStep, setCurrentStep] = useState<'login' | 'otp'>("login");
    const [loginFormInitialValues] = useState<any>(_.cloneDeep(LoginFormInitialValues));
    const [otpFormInitialValues, setOTPFormInitialValues] = useState<any>(_.cloneDeep(OTPFormInitialValues));
    const [isOTPBeingRequested, setIsOTPBeingRequested] = useState<boolean>(false);
    const [otpTimer, setOtpTimer] = useState<number>(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const runOTPCountdown = useCallback(() => {
        let timer = OTP_VALIDITY;
        setOtpTimer(timer);
        const interval = setInterval(() => {
            timer--;
            setOtpTimer(timer);
            if (timer === 0) {
                clearInterval(interval);
            }
        }, 1000);
    }, []);

    const onOTPRequestSend = useCallback((values: any, {setSubmitting, setErrors}: FormikHelpers<any>) => {
        setSubmitting(true);
        CommonService._account.RequestOTPAPICall(values)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setOTPFormInitialValues((prev: any) => {
                    return {...prev, ...values}
                });
                setCurrentStep('otp');
                runOTPCountdown();
                setSubmitting(false);
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                setSubmitting(false);
            });
    }, [runOTPCountdown]);

    const onLogin = useCallback((values: any, {setSubmitting, setErrors}: FormikHelpers<any>) => {
        setSubmitting(true);
        CommonService._account.VerifyOTPAPICall(values)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setSubmitting(false);
                dispatch(setLoggedInUserData(response?.data?.user));
                dispatch(setLoggedInUserToken(response?.data?.token));
                navigate(CommonService._routeConfig.ClientOnboarding());
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                setSubmitting(false);
            })
    }, [dispatch, navigate]);

    const handleOTPBackNavigation = useCallback(() => {
        setCurrentStep('login');
    }, []);

    const handleResendOTP = useCallback((values: any) => {
        setIsOTPBeingRequested(true);
        CommonService._account.RequestOTPAPICall(values)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                runOTPCountdown();
                setIsOTPBeingRequested(false);
            })
            .catch((error: any) => {
                CommonService._alert.showToast(error.error || 'Error sending OTP', "error");
                setIsOTPBeingRequested(false);
            })
    }, [runOTPCountdown]);

    return (
        <div className={'login-screen'}>
            <div className="login-poster">
            </div>
            <div className="login-form">
                {
                    currentStep === 'login' && <Formik
                        validationSchema={loginFormValidationSchema}
                        initialValues={loginFormInitialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onOTPRequestSend}
                    >
                        {(formik) => {
                            const {values, validateForm, isValid, isSubmitting: isOTPBeingSent} = formik;
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form className="t-form" noValidate={true}>
                                    <div className="t-form-controls">
                                        <div className="login-form__header">
                                            <div className="login-form__header__logo">
                                                <img src={ImageConfig.Logo} alt={'kinergy branding'}/>
                                            </div>
                                        </div>
                                        <div className="login-form__body">
                                            <div className="login-form__body__title">
                                                Welcome to Kinergy!
                                            </div>
                                            <div className="login-form__body__description">
                                                Let’s get started.
                                            </div>
                                            <div className="login-form__body__controls t-form-controls">
                                                {
                                                    values.login_mode === 'email' &&
                                                    <Field name={'username'} className="t-form-control">
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    id={'email_input'}
                                                                    label={'Please enter your Email Address to login.'}
                                                                    placeholder={'example@email.com'}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                }
                                                {
                                                    values.login_mode === 'phone' &&
                                                    <Field name={'username'} className="t-form-control">
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikPhoneInputComponent
                                                                    id={'phone_input'}
                                                                    label={'Please enter your Cell Phone Number to login.'}
                                                                    placeholder={'(000-000-0000)'}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                }
                                            </div>
                                            <div className="login-form__body__actions t-form-actions">
                                                <ButtonComponent
                                                    id={'proceed_btn'}
                                                    type={"submit"}
                                                    prefixIcon={<ImageConfig.ArrowRightCircle/>}
                                                    fullWidth={true}
                                                    disabled={!isValid || isOTPBeingSent}
                                                    isLoading={isOTPBeingSent}
                                                >
                                                    Proceed
                                                </ButtonComponent>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                }
                {
                    currentStep === 'otp' && <Formik
                        validationSchema={otpFormValidationSchema}
                        initialValues={otpFormInitialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onLogin}
                    >
                        {(formik) => {
                            const {values, validateForm, isValid, isSubmitting: isLoggingIn} = formik;
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form className="t-form" noValidate={true}>
                                    <div className="login-form__back_navigation" onClick={handleOTPBackNavigation}>
                                        <IconButtonComponent className="login-form__back_navigation_icon">
                                            <ImageConfig.LeftArrow/>
                                        </IconButtonComponent>
                                        <span className="login-form__back_navigation_text"> Previous </span>
                                    </div>
                                    <div className="t-form-controls">
                                        <div className="login-form__body">
                                            <div className="login-form__body__title">
                                                One Time Password (OTP)
                                                Verification
                                            </div>
                                            <div className="login-form__body__description">
                                                Please enter the OTP we sent to you on {values.username}.
                                            </div>
                                            <div className="login-form__body__controls t-form-controls">
                                                <Field name={'otp'} className="t-form-control">
                                                    {
                                                        (field: FieldProps) => (
                                                            <FormikOTPComponent
                                                                placeholder={'000000'}
                                                                required={true}
                                                                formikField={field}
                                                                fullWidth={true}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                                <div className="login-form__body__controls__resend_otp">
                                                    {
                                                        otpTimer > 0 ?
                                                            <LinkComponent disabled={true}>
                                                                {CommonService.getMinsAndSecondsFromSeconds(otpTimer).minutes + " : " + CommonService.getMinsAndSecondsFromSeconds(otpTimer).seconds}
                                                            </LinkComponent>
                                                            :
                                                            <LinkComponent
                                                                onClick={() => handleResendOTP(values)}
                                                                disabled={isOTPBeingRequested}
                                                            >
                                                                {isOTPBeingRequested ? "Sending OTP" : "Resend OTP"}
                                                            </LinkComponent>
                                                    }
                                                </div>
                                            </div>
                                            <div className="login-form__body__controls__button">
                                                <ButtonComponent
                                                    id={'submit_btn'}
                                                    type={"submit"}
                                                    fullWidth={true}
                                                    disabled={isLoggingIn || !isValid}
                                                    isLoading={isLoggingIn}
                                                >
                                                    Submit
                                                </ButtonComponent>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                }
            </div>
        </div>
    )
}

export default LoginScreen;
