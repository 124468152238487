import {
    CLIENT_INJURY_DETAILS_ROUTE,
    CLIENT_INJURY_SUCCESS_ROUTE, CLIENT_REGISTRATION_CONSENT_ROUTE, CLIENT_REGISTRATION_ROUTE,
    COMING_SOON_ROUTE,
    LOGIN_ROUTE,
    NOT_FOUND_ROUTE, ON_BOARDING_ROUTE,
    TEST_ROUTE,
} from "../../constants/RoutesConfig";

const DefaultRoute = () => {
    return "/";
}

const NotFoundRoute = () => {
    return NOT_FOUND_ROUTE;
}

const TestRoute = () => {
    return TEST_ROUTE;
}

const LoginRoute = () => {
    return LOGIN_ROUTE;
}

const ComingSoonRoute = () => {
    return COMING_SOON_ROUTE;
}

const ClientInjuryDetails = () => {
    return CLIENT_INJURY_DETAILS_ROUTE;
}

const ClientInjurySuccess = (context: 'injury-form' | 'client-registration') => {
    return CLIENT_INJURY_SUCCESS_ROUTE + "?context=" + context;
}

const ClientOnboarding = () => {
    return ON_BOARDING_ROUTE;
}

const ClientRegistration = () => {
    return CLIENT_REGISTRATION_ROUTE;
}

const ClientRegistrationConsent = () => {
    return CLIENT_REGISTRATION_CONSENT_ROUTE;
}


const RouteConfigService = {
    DefaultRoute,
    NotFoundRoute,
    TestRoute,
    ComingSoonRoute,
    ClientInjuryDetails,
    ClientInjurySuccess,
    LoginRoute,
    ClientOnboarding,
    ClientRegistration,
    ClientRegistrationConsent
}

export default RouteConfigService;
