import "./ClientRegistrationMedicalHistoryMedicationsSupplimentsComponent.scss";
import * as Yup from "yup";
import {IAPIResponseType} from "../../shared/models/api.model";
import {useSelector} from "react-redux";
import {ImageConfig, Misc} from "../../constants";
import {IRootReducerState} from "../../store/reducers";
import {useCallback, useEffect, useState} from "react";
import {CommonService} from "../../shared/services";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import _ from "lodash";
import ButtonComponent from "../../shared/components/button/ButtonComponent";
import FormikTextAreaComponent from "../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent";

interface ClientRegistrationMedicalHistoryMedicationsSupplimentsComponentProps {
    onNext: ()=> void;
}


const ClientMedicalSupplementsValidationSchema = Yup.object({
    medications: Yup.object({
        prescription_medication: Yup.string().required('Prescription Medication is required'),
        non_prescription_medication: Yup.string().required('Non Prescription Medication is required'),
    }),
});

const ClientMedicalSupplementsInitialValues: any = {
    medications: {
        prescription_medication: "",
        non_prescription_medication: "",
    }
};

const ClientRegistrationMedicalHistoryMedicationsSupplimentsComponent = (props: ClientRegistrationMedicalHistoryMedicationsSupplimentsComponentProps) => {

    const {onNext} = props;
    const [clientMedicalSupplementsInitialValues, setClientMedicalSupplementsInitialValues] = useState<any>(_.cloneDeep(ClientMedicalSupplementsInitialValues));

    const {
        clientMedicalHistoryDetails,
        isClientMedicalHistoryDetailsLoaded
    } = useSelector((state: IRootReducerState) => state.client);

    useEffect(() => {
        if (clientMedicalHistoryDetails) {
            setClientMedicalSupplementsInitialValues(clientMedicalHistoryDetails);
        }
    }, [clientMedicalHistoryDetails, isClientMedicalHistoryDetailsLoaded]);

    const onSubmit = useCallback((values: any, {setErrors}: FormikHelpers<any>) => {
        const payload = {...values};
        CommonService._client.ClientMedicalHistoryQuestionnaireMedicationSupplementsDetailsSaveAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                onNext();
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error, true);
            })
    }, [onNext]);

    return (
        <div className={'client-registration-medical-history-medications-suppliments-component'}>
            <Formik
                validationSchema={ClientMedicalSupplementsValidationSchema}
                initialValues={clientMedicalSupplementsInitialValues}
                onSubmit={onSubmit}
                validateOnChange={false}
                validateOnBlur={true}
                enableReinitialize={true}
                validateOnMount={true}>
                {({values, isValid, isSubmitting, validateForm}) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        validateForm();
                    }, [validateForm, values]);
                    return (
                        <Form noValidate={true} className={"t-form"}>
                            <div className="ts-row">
                                <div className="ts-col-12">
                                    <Field name={`medications.prescription_medication`}>
                                        {
                                            (field: FieldProps) => (
                                                <FormikTextAreaComponent
                                                    id={"prescription_input"}
                                                    label={"Prescription Medications"}
                                                    placeholder={"Prescription Medications"}
                                                    required={true}
                                                    formikField={field}
                                                    fullWidth={true}
                                                />
                                            )
                                        }
                                    </Field>
                                    <Field name={`medications.non_prescription_medication`}>
                                        {
                                            (field: FieldProps) => (
                                                <FormikTextAreaComponent
                                                    id={"non_prescription_input"}
                                                    label={"Non-Prescription Medications / Supplements"}
                                                    placeholder={"Non-Prescription Medications / Supplements"}
                                                    required={true}
                                                    formikField={field}
                                                    fullWidth={true}
                                                />
                                            )
                                        }
                                    </Field>
                                </div>
                            </div>
                            <div className="t-form-actions">
                                <ButtonComponent
                                    id={"submit_btn"}
                                    type={"submit"}
                                    isLoading={isSubmitting}
                                    disabled={isSubmitting || !isValid}
                                    suffixIcon={<ImageConfig.ArrowRightCircle/>}
                                >
                                    Next
                                </ButtonComponent>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );

};

export default ClientRegistrationMedicalHistoryMedicationsSupplimentsComponent;
