import './DatePickerComponent.scss'
import React, {useCallback, useEffect, useState} from 'react';
import DatePicker from 'react-date-picker';
import {ImageConfig} from "../../../../constants";
import ErrorTextComponent from "../../error-text/ErrorTextComponent";
import LabelComponent from "../../label/LabelComponent";
import {IDatePickerProps} from "../../../models/form-controls.model";

interface DatePickerComponentProps extends IDatePickerProps {
    name?: string;
    value?: string;
    hasError?: boolean;
    errorMessage?: any;
}

const DatePickerComponent = (props: DatePickerComponentProps) => {

    const [value, setValue] = useState<any>(props.value);
    const {
        className,
        hasError,
        required,
        errorMessage,
        fullWidth,
        disabled,
        minDate,
        maxDate,
        label,
        onCalenderClose,
        onChange,
    } = props;

    const format = props.format ? props.format : 'MM-dd-y';
    const clearable = props.clearable !== undefined ? props.format : true;

    const handleDatePickerOnChange = useCallback((value: any) => {
        setValue(value);
        if (onChange) {
            onChange(value);
        }
    }, [onChange]);

    const handleCalendarClose = useCallback(() => {
        if (onCalenderClose) {
            onCalenderClose()
        }
    }, [onCalenderClose]);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    return (
        <div
            className={`date-picker-component ${fullWidth ? "fullWidth" : ''} ${disabled ? 'disabled' : ''} ${hasError ? 'has-error' : ''}`}>
            {label && <LabelComponent title={label || ''} required={required}/>}
            <DatePicker
                onChange={handleDatePickerOnChange}
                onCalendarClose={handleCalendarClose}
                format={format}
                dayPlaceholder={'DD'}
                monthPlaceholder={'MM'}
                yearPlaceholder={'YYYY'}
                minDate={minDate}
                maxDate={maxDate}
                disabled={disabled}
                clearIcon={(clearable && value) ? <ImageConfig.DatePickerClearIcon/> : null}
                calendarIcon={<img src={ImageConfig.DatePicker} alt={"calendar-icon"}/>}
                className={'date-picker-field ' + (hasError ? "has-error" : '') + ' ' + className}
                value={value ? new Date(value) : undefined}
            />
            {(errorMessage && hasError) && (
                <ErrorTextComponent error={errorMessage}/>
            )}
        </div>
    );
};

export default DatePickerComponent;
