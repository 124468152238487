import "./ClientAddressInformationComponent.scss";
import * as Yup from "yup";
import {IClientAddressDetails} from "../../../shared/models/client.model";
import {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import {ImageConfig, Misc} from "../../../constants";
import FormDebuggerComponent from "../../../shared/components/form-debugger/FormDebuggerComponent";
import FormikInputComponent from "../../../shared/components/form-controls/formik-input/FormikInputComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";

interface ClientAddressInformationComponentProps {
    onNext: () => void;
}

const ClientAddressInformationFormValidationSchema = Yup.object({
    address: Yup.object({
        address_line: Yup.string()
            .min(1, 'Address line is required')
            .max(100, 'Address line cannot be more than 100 characters')
            .required('Address Line is required'),
        city: Yup.string()
            .min(1, 'City is required')
            .max(100, 'City cannot be more than 100 characters')
            .required('City is required'),
        state: Yup.string()
            .min(1, 'State is required')
            .max(100, 'State cannot be more than 100 characters')
            .required('State is required'),
        country: Yup.string()
            .min(2, 'Country must be at least 2 characters')
            .max(100, 'Country cannot be more than 100 characters')
            .required('Country Code is required'),
        zip_code: Yup.string()
            .min(5, 'Enter valid 5 digit zipcode')
            .max(5, 'Enter valid 5 digit zipcode')
            .required('Enter valid 5 digit zipcode'),
    })
});

const ClientAddressInformationFormInitialValues: IClientAddressDetails = {
    address: {
        address_line: "",
        city: "",
        state: "",
        country: "",
        zip_code: "",
    },
}


const ClientAddressInformationComponent = (props: ClientAddressInformationComponentProps) => {

    const {onNext} = props;
    const [clientAddressInformationFormInitialValues, setClientAddressInformationFormInitialValues] = useState<IClientAddressDetails>(_.cloneDeep(ClientAddressInformationFormInitialValues));

    const {
        clientBasicDetails,
        isClientBasicDetailsLoaded,
    } = useSelector((state: IRootReducerState) => state.client);

    useEffect(() => {
        if (isClientBasicDetailsLoaded) {
            setClientAddressInformationFormInitialValues(clientBasicDetails);
        }
    }, [clientBasicDetails, isClientBasicDetailsLoaded]);

    const onSubmit = useCallback((values: any, {setSubmitting, setErrors}: FormikHelpers<any>) => {
        setSubmitting(true);
        CommonService._client.ClientBasicDetailsSaveAPICall(values)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setSubmitting(false);
                onNext();
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                setSubmitting(false);
            })
    }, [onNext]);

    return (
        <div className={'client-address-information-component'}>
            <Formik
                validationSchema={ClientAddressInformationFormValidationSchema}
                initialValues={clientAddressInformationFormInitialValues}
                validateOnChange={false}
                validateOnBlur={true}
                enableReinitialize={true}
                validateOnMount={true}
                onSubmit={onSubmit}
            >
                {({values, validateForm, isValid, isSubmitting}) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        validateForm();
                    }, [validateForm, values]);
                    return (
                        <Form className="t-form" noValidate={true}>
                            <FormDebuggerComponent values={values} showDebugger={true}/>
                            <div className="t-form-controls">
                                <div className="ts-row">
                                    <div className="ts-col-12">
                                        <Field name={'address.address_line'}>
                                            {
                                                (field: FieldProps) => (
                                                    <FormikInputComponent
                                                        label={'Address Line'}
                                                        placeholder={'Enter Your Street Address'}
                                                        required={true}
                                                        formikField={field}
                                                        fullWidth={true}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                </div>
                                <div className="ts-row">
                                    <div className="ts-col-lg-6">
                                        <Field name={'address.city'}>
                                            {
                                                (field: FieldProps) => (
                                                    <FormikInputComponent
                                                        label={'City'}
                                                        placeholder={'Enter City'}
                                                        required={true}
                                                        formikField={field}
                                                        fullWidth={true}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div className="ts-col-lg-6">
                                        <Field name={'address.state'}>
                                            {
                                                (field: FieldProps) => (
                                                    <FormikInputComponent
                                                        label={'State'}
                                                        placeholder={'Enter State'}
                                                        required={true}
                                                        formikField={field}
                                                        fullWidth={true}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                </div>
                                <div className="ts-row">
                                    <div className="ts-col-lg-6">
                                        <Field name={'address.zip_code'}>
                                            {
                                                (field: FieldProps) => (
                                                    <FormikInputComponent
                                                        label={'ZIP Code'}
                                                        placeholder={'Enter ZIP Code'}
                                                        required={true}
                                                        formikField={field}
                                                        fullWidth={true}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div className="ts-col-lg-6">
                                        <Field name={'address.country'}>
                                            {
                                                (field: FieldProps) => (
                                                    <FormikInputComponent
                                                        label={'Country'}
                                                        placeholder={'Enter Country'}
                                                        required={true}
                                                        formikField={field}
                                                        fullWidth={true}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className="t-form-actions">
                                <ButtonComponent
                                    type={"submit"}
                                    suffixIcon={<ImageConfig.ArrowRightCircle/>}
                                    disabled={!isValid || isSubmitting}
                                    isLoading={isSubmitting}
                                >
                                    Next
                                </ButtonComponent>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );

};

export default ClientAddressInformationComponent;
