import "./ClientRegistrationBasicDetailsComponent.scss";
import {useCallback, useEffect, useState} from "react";
import ClientPersonalDetailsComponent from "./client-personal-details/ClientPersonalDetailsComponent";
import {useSearchParams} from "react-router-dom";
import ClientContactInformationComponent from "./client-contact-information/ClientContactInformationComponent";
import ClientAddressInformationComponent from "./client-address-information/ClientAddressInformationComponent";
import ClientWorkInformationComponent from "./client-work-information/ClientWorkInformationComponent";
import {setClientRegistrationSubStep} from "../../store/actions/state-management.action";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "../../store/reducers";
import {getClientBasicDetails} from "../../store/actions/client.action";

interface ClientRegistrationBasicDetailsComponentProps {
    onNext: () => void;
}

type ClientBasicDetailsType =
    'personal-details'
    | 'contact-information'
    | 'address-information'
    | 'work-information'

const ClientBasicDetailsKeyMapping: {
    [key in ClientBasicDetailsType]: string
} = {
    'personal-details': 'Personal Details',
    'contact-information': 'Contact Information',
    'address-information': 'Address Information',
    'work-information': 'Work Information'
}

const ClientRegistrationBasicDetailsSteps: ClientBasicDetailsType[] = ['personal-details', 'contact-information', 'address-information', 'work-information'];
const DEFAULT_SUB_STEP_KEY = 'personal-details';

const ClientRegistrationBasicDetailsComponent = (props: ClientRegistrationBasicDetailsComponentProps) => {

    const {onNext} = props;
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentStep, setCurrentStep] = useState<ClientBasicDetailsType>('personal-details');

    const {
        clientRegistrationSubStep
    } = useSelector((state: IRootReducerState) => state.stateManagement);

    useEffect(() => {
        const subStep: ClientBasicDetailsType = searchParams.get("subStep") as ClientBasicDetailsType;
        if (ClientRegistrationBasicDetailsSteps.includes(subStep)) {
            setCurrentStep(subStep as ClientBasicDetailsType);
            dispatch(setClientRegistrationSubStep(subStep));
        } else {
            searchParams.set("subStep", DEFAULT_SUB_STEP_KEY);
            setSearchParams(searchParams);
            setCurrentStep(DEFAULT_SUB_STEP_KEY);
            dispatch(setClientRegistrationSubStep(DEFAULT_SUB_STEP_KEY));
        }
    }, [dispatch, searchParams, setSearchParams]);

    const handleNext = useCallback(() => {
        const nextStepIndex = ClientRegistrationBasicDetailsSteps.indexOf(currentStep) + 1;
        if (nextStepIndex < ClientRegistrationBasicDetailsSteps.length) {
            const nextStep = ClientRegistrationBasicDetailsSteps[nextStepIndex];
            searchParams.set("subStep", nextStep);
            setSearchParams(searchParams);
            setCurrentStep(nextStep);
            dispatch(setClientRegistrationSubStep(nextStep));
        } else {
            onNext();
        }
        dispatch(getClientBasicDetails());
    }, [onNext, dispatch, currentStep, searchParams, setSearchParams]);

    useEffect(() => {
        if (clientRegistrationSubStep) {
            setCurrentStep(clientRegistrationSubStep);
        }
    }, [clientRegistrationSubStep]);

    return (
        <div className="client-registration-details-block client-registration-basic-details-component">
            <div className="client-registration-details-block__header">
                <div className="client-registration-details-block__title">
                    {ClientBasicDetailsKeyMapping[currentStep]}
                </div>
            </div>
            <div className="client-registration-details-block__body">
                {
                    currentStep === 'personal-details' &&
                    <ClientPersonalDetailsComponent onNext={handleNext}/>
                }
                {
                    currentStep === 'contact-information'
                    && <ClientContactInformationComponent onNext={handleNext}/>
                }
                {
                    currentStep === 'address-information'
                    && <ClientAddressInformationComponent onNext={handleNext}/>
                }
                {
                    currentStep === 'work-information'
                    && <ClientWorkInformationComponent onNext={handleNext}/>
                }
            </div>
        </div>
    );

};

export default ClientRegistrationBasicDetailsComponent;
