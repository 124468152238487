import "./ClientRegistrationMedicalHistoryFemalesOnlyComponent.scss";
import {CommonService} from "../../shared/services";
import {IAPIResponseType} from "../../shared/models/api.model";
import {ImageConfig, Misc} from "../../constants";
import React, {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import * as Yup from "yup";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import ButtonComponent from "../../shared/components/button/ButtonComponent";
import FormikRadioButtonGroupComponent
    from "../../shared/components/form-controls/formik-radio-button/FormikRadioButtonComponent";
import HorizontalLineComponent from "../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../store/reducers";

interface ClientRegistrationMedicalHistoryFemalesOnlyComponentProps {
    onNext: () => void;
}


const ClientMedicalFemaleOnlyValidationSchema = Yup.object({
    females_only_questions: Yup.object({
        "Pregnant or trying to get pregnant?": Yup.string().required('Pregnant or trying to get pregnant is required'),
        "Nursing?": Yup.string().required('Nursing is required')
    }),
});

const ClientMedicalFemaleOnlyInitialValues: any = {
    "females_only_questions": {
        "Pregnant or trying to get pregnant?": "",
        "Nursing?": ""
    }
};

const FormQuestions = [
    {
        key: "Pregnant or trying to get pregnant?",
        title: "Pregnant or trying to get pregnant?",
    },
    {
        key: "Nursing?",
        title: "Nursing?",
    }
]

const ClientRegistrationMedicalHistoryFemalesOnlyComponent = (props: ClientRegistrationMedicalHistoryFemalesOnlyComponentProps) => {

    const {onNext} = props;
    const [clientMedicalFemaleOnlyInitialValues, setClientMedicalFemaleOnlyInitialValues] = useState<any>(_.cloneDeep(ClientMedicalFemaleOnlyInitialValues));

    const {
        clientMedicalHistoryDetails,
    } = useSelector((state: IRootReducerState) => state.client);

    useEffect(() => {
        if (clientMedicalHistoryDetails) {
            setClientMedicalFemaleOnlyInitialValues({
                females_only_questions: clientMedicalHistoryDetails.females_only_questions
            });
        }
    }, [clientMedicalHistoryDetails]);

    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        const payload = {...values};
        setSubmitting(true);
        CommonService._client.ClientMedicalHistoryFemaleOnlyDetailsSaveAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setSubmitting(false);
                setClientMedicalFemaleOnlyInitialValues(_.cloneDeep(values));
                onNext();
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                setSubmitting(false);
            })
    }, [onNext]);


    return (
        <div className={'client-registration-medical-history-females-only-component'}>
            <Formik
                validationSchema={ClientMedicalFemaleOnlyValidationSchema}
                initialValues={clientMedicalFemaleOnlyInitialValues}
                onSubmit={onSubmit}
                validateOnChange={false}
                validateOnBlur={true}
                enableReinitialize={true}
                validateOnMount={true}>
                {({values, isValid, isSubmitting, validateForm}) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        validateForm();
                    }, [validateForm, values]);
                    return (
                        <Form noValidate={true} className={"t-form"}>
                            {
                                FormQuestions.map((question: any, index) => {
                                    const {key, title} = question;
                                    return <>
                                        <div className="ts-row ts-align-items-center" key={key}>
                                            <div className="ts-col-md-9">
                                                {title}
                                            </div>
                                            <div className="ts-col-md-3">
                                                <Field name={`females_only_questions.${key}`}>
                                                    {
                                                        (field: FieldProps) => (
                                                            <FormikRadioButtonGroupComponent
                                                                options={CommonService._staticData.yesNoOptions}
                                                                displayWith={(option) => option.title}
                                                                valueExtractor={(option) => option.title}
                                                                required={true}
                                                                formikField={field}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                            </div>
                                        </div>
                                        {index !== FormQuestions.length -1 && <HorizontalLineComponent/>}
                                    </>
                                })
                            }
                            <div className="t-form-actions">
                                <ButtonComponent
                                    id={"next_btn"}
                                    type={"submit"}
                                    isLoading={isSubmitting}
                                    disabled={isSubmitting || !isValid}
                                    suffixIcon={<ImageConfig.ArrowRightCircle/>}
                                >
                                    Next
                                </ButtonComponent>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );

};

export default ClientRegistrationMedicalHistoryFemalesOnlyComponent;
