import "./LinkComponent.scss";
import {PropsWithChildren} from "react";

interface LinkComponentProps {
    disabled?: boolean;
    onClick?: () => void;
    
}

const LinkComponent = (props: PropsWithChildren<LinkComponentProps>) => {

    const {onClick, disabled, children} = props;

    return (
        <div className={`link-component ${disabled ? 'disabled' : ''}`} onClick={() => {
            onClick && onClick();
        }}>
            {children}
        </div>
    );

};

export default LinkComponent;
