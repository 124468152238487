import "./ClientRegistrationCommunicationReferralDetailsComponent.scss";
import * as Yup from "yup";
import {IClientCommunicationReferralDetails} from "../../shared/models/client.model";
import {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "../../store/reducers";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {CommonService} from "../../shared/services";
import {IAPIResponseType} from "../../shared/models/api.model";
import {ImageConfig, Misc} from "../../constants";
import FormDebuggerComponent from "../../shared/components/form-debugger/FormDebuggerComponent";
import FormikInputComponent from "../../shared/components/form-controls/formik-input/FormikInputComponent";
import FormikSelectDropdownComponent
    from "../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
import ButtonComponent from "../../shared/components/button/ButtonComponent";
import DataLabelValueComponent from "../../shared/components/data-label-value/DataLabelValueComponent";
import HorizontalLineComponent from "../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import FormControlLabelComponent from "../../shared/components/form-control-label/FormControlLabelComponent";
import {getClientCommunicationReferralDetails} from "../../store/actions/client.action";

interface ClientRegistrationCommunicationReferralDetailsComponentProps {
    onNext: () => void;
}

const ClientCommunicationReferralDetailsFormValidationSchema = Yup.object({});

const ClientCommunicationReferralDetailsFormInitialValues: IClientCommunicationReferralDetails = {
    communication_preferences: {
        appointment_reminders: "",
        appointment_confirmations: ""
    },
    referral_details: {
        source: "",
        source_info_name: "",
        source_info_phone: "",
        source_info_email: "",
        source_info_relationship: "",
    }
}

const ClientRegistrationCommunicationReferralDetailsComponent = (props: ClientRegistrationCommunicationReferralDetailsComponentProps) => {

    const {onNext} = props;
    const dispatch = useDispatch();
    const [clientCommunicationReferralDetailsFormInitialValues, setClientCommunicationReferralDetailsFormInitialValues] = useState<IClientCommunicationReferralDetails>(_.cloneDeep(ClientCommunicationReferralDetailsFormInitialValues));

    const {
        clientCommunicationReferralDetails,
        isClientCommunicationReferralDetailsLoaded,
    } = useSelector((state: IRootReducerState) => state.client);

    const {
        socialMediaPlatformList,
        communicationModeTypeList,
        referralTypeList,
        relationshipList
    } = useSelector((state: IRootReducerState) => state.staticData);

    useEffect(() => {
        if (clientCommunicationReferralDetails) {
            setClientCommunicationReferralDetailsFormInitialValues(clientCommunicationReferralDetails);
        }
    }, [clientCommunicationReferralDetails, isClientCommunicationReferralDetailsLoaded]);

    const onSubmit = useCallback((values: any, {setSubmitting, setErrors}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {...values, is_communication_details_updated: true};
        CommonService._client.ClientCommunicationReferralDetailsSaveAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setSubmitting(false);
                dispatch(getClientCommunicationReferralDetails());
                onNext();
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                setSubmitting(false);
            })
    }, [dispatch, onNext]);

    return (
        <div
            className={'client-registration-details-block client-registration-communication-referral-details-component'}>
            <div className="client-registration-details-block__header">
                <div className="client-registration-details-block__title">
                    Communication and Referral Details
                </div>
            </div>
            <div className="client-registration-details-block__body">
                <Formik
                    validationSchema={ClientCommunicationReferralDetailsFormValidationSchema}
                    initialValues={clientCommunicationReferralDetailsFormInitialValues}
                    validateOnChange={false}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    validateOnMount={true}
                    onSubmit={onSubmit}
                >
                    {({values, setFieldValue, validateForm, isValid, isSubmitting}) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                            validateForm();
                        }, [validateForm, values]);
                        return (
                            <Form className="t-form" noValidate={true}>
                                <FormDebuggerComponent values={values} showDebugger={false}/>
                                <div className="t-form-controls">
                                    <FormControlLabelComponent label={"Communication Preferences"}/>
                                    <div className="ts-row">
                                        <div className="ts-col-md-8">
                                            <DataLabelValueComponent label={"Appointment Reminders"}>
                                                How would you like to receive appointment reminders
                                            </DataLabelValueComponent>
                                        </div>
                                        <div className="ts-col-md-4">
                                            <Field name={`communication_preferences.appointment_reminders`}>
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikSelectDropdownComponent
                                                            options={communicationModeTypeList}
                                                            placeholder={"Select Option"}
                                                            formikField={field}
                                                            fullWidth={true}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="ts-row">
                                        <div className="ts-col-md-8">
                                            <DataLabelValueComponent label={"Appointment Confirmations"}>
                                                How would you like to receive appointment confirmations
                                            </DataLabelValueComponent>
                                        </div>
                                        <div className="ts-col-md-4">
                                            <Field name={`communication_preferences.appointment_confirmations`}>
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikSelectDropdownComponent
                                                            options={communicationModeTypeList}
                                                            placeholder={"Select Option"}
                                                            formikField={field}
                                                            fullWidth={true}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                    </div>
                                    <HorizontalLineComponent/>
                                    <FormControlLabelComponent label={"Referral Details"}/>
                                    <div className="ts-row">
                                        <div className="ts-col-md-8">
                                            <DataLabelValueComponent label={"How did you find us?"}>
                                                Please choose an option that best describes how you heard about us.
                                            </DataLabelValueComponent>
                                        </div>
                                        <div className="ts-col-md-4">
                                            <Field name={`referral_details.source`}>
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikSelectDropdownComponent
                                                            options={referralTypeList}
                                                            placeholder={"Select Option"}
                                                            formikField={field}
                                                            fullWidth={true}
                                                            onUpdate={() => {
                                                                setFieldValue('referral_details.source_info_name', '');
                                                                setFieldValue('referral_details.source_info_phone', '');
                                                                setFieldValue('referral_details.source_info_email', '');
                                                                setFieldValue('referral_details.source_info_relationship', '');
                                                            }}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                    </div>
                                    {
                                        values.referral_details.source === "friends_family_colleague" && <>
                                            <DataLabelValueComponent label={"Please complete the following:"}>
                                            </DataLabelValueComponent>
                                            <div className="ts-row">
                                                <div className="ts-col-md-4">
                                                    <Field name={`referral_details.source_info_name`}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={"Full Name"}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="ts-col-md-4">
                                                    <Field name={`referral_details.source_info_phone`}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={"Phone Number"}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="ts-row">
                                                <div className="ts-col-md-4">
                                                    <Field name={`referral_details.source_info_email`}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={"Email"}
                                                                    type={"email"}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="ts-col-md-4">
                                                    <Field name={`referral_details.source_info_relationship`}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    options={relationshipList}
                                                                    label={"Relationship"}
                                                                    placeholder={"Select Option"}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        values.referral_details.source === "social_media" && <>
                                            <DataLabelValueComponent label={"Please select an option:"}>
                                            </DataLabelValueComponent>
                                            <div className="ts-row">
                                                <div className="ts-col-md-4">
                                                    <Field name={`referral_details.source_info_name`}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    options={socialMediaPlatformList}
                                                                    placeholder={"Select Option"}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        values.referral_details.source === "other" && <>
                                            <DataLabelValueComponent label={"Please explain"}>
                                            </DataLabelValueComponent>
                                            <div className="ts-row">
                                                <div className="ts-col-md-4">
                                                    <Field name={`referral_details.source_info_name`}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={"Other info"}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="t-form-actions">
                                    <ButtonComponent
                                        id={'next_btn'}
                                        type={"submit"}
                                        suffixIcon={<ImageConfig.ArrowRightCircle/>}
                                        disabled={!isValid || isSubmitting}
                                        isLoading={isSubmitting}
                                    >
                                        Next
                                    </ButtonComponent>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );


};

export default ClientRegistrationCommunicationReferralDetailsComponent;
