import "./ClientRegistrationConsentScreen.scss";
import IconButtonComponent from "../../shared/components/icon-button/IconButtonComponent";
import {ImageConfig, Misc} from "../../constants";
import {useCallback, useEffect, useState} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import _ from "lodash";
import {IClientRegistrationConsentDetails} from "../../shared/models/client.model";
import FormikInputComponent from "../../shared/components/form-controls/formik-input/FormikInputComponent";
import FormikDatePickerComponent
    from "../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent";
import FormikCheckBoxComponent from "../../shared/components/form-controls/formik-check-box/FormikCheckBoxComponent";
import SignaturePadComponent from "../../shared/components/signature-pad/SignaturePadComponent";
import ButtonComponent from "../../shared/components/button/ButtonComponent";
import {CommonService} from "../../shared/services";
import {IAPIResponseType} from "../../shared/models/api.model";
import {useNavigate} from "react-router-dom";
import FormControlLabelComponent from "../../shared/components/form-control-label/FormControlLabelComponent";
import ErrorComponent from "../../shared/components/error/ErrorComponent";
import FormDebuggerComponent from "../../shared/components/form-debugger/FormDebuggerComponent";

interface ClientRegistrationConsentScreenProps {

}

const registrationConsentFormValidationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    date: Yup.string(),
    is_acknowledged: Yup.boolean().oneOf([true], 'Consent must be acknowledged'),
    is_authorized_representative: Yup.boolean(),
    representative_name: Yup.string().when("is_authorized_representative", {
        is: true,
        then: Yup.string().required('Authorized Representative Name is required')
    }),
    signature: Yup.string().required()
});

const RegistrationConsentFormInitialValues: IClientRegistrationConsentDetails = {
    name: "",
    date: new Date(),
    is_acknowledged: false,
    is_authorized_representative: false,
    representative_name: "",
    signature: ""
}

const ClientRegistrationConsentScreen = (props: ClientRegistrationConsentScreenProps) => {

    const navigate = useNavigate();
    const [registrationFormInitialValues] = useState<IClientRegistrationConsentDetails>(_.cloneDeep(RegistrationConsentFormInitialValues));

    const onSubmit = useCallback((values: any, {setSubmitting, setErrors}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = _.cloneDeep(values);
        payload.date = CommonService.convertDateFormat(payload.date, "MM-DD-YYYY");
        CommonService._client.SubmitRegistrationConsentAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setSubmitting(false);
                navigate(CommonService._routeConfig.ClientInjurySuccess('client-registration'));
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                setSubmitting(false);
            })
    }, [navigate]);

    const handleNavigateBack = useCallback(() => {
       navigate('/registration?step=medical-history-questionnaire&subStep=musculoskeletal-history');
    }, [navigate]);

    return (
        <div className={'client-registration-consent-screen'}>
            <div className={'client-registration-consent-container ts-row'}>
                <div className="client-registration-consent-left ts-col-md-12 ts-col-lg-6">
                    <FormControlLabelComponent className={"client-registration-consent-title"}
                                               label={"Certification and Consent"} size={"lg"}/>
                    <div className="client-registration-consent-data">
                        I hereby certify that I have completed this form to the best of my ability and I acknowledge
                        that
                        all the information that I have
                        supplied on this form is true, accurate, current, and complete.
                        <br/>
                        <br/>
                        I understand that my medical history is a very important factor in the direction of my care.
                        Medical
                        or physical conditions which are known to me, but which I do not disclose on this form or to my
                        provider, may result in serious injury to me. If any of the above conditions change, I will
                        immediately inform Kinergy Sports Medicine and Performance or my provider. I knowingly and
                        willingly
                        assume all risks of injury resulting from my failure to disclose accurate, complete and updated
                        information in accordance with the above questionnaire.
                        <br/>
                        <br/>
                        I hereby consent to and authorize Kinergy Sports Medicine and Performance to perform any medical
                        assessments and treatments deemed necessary for my medical care. I understand and am informed
                        that,
                        as in the practice of medicine, rehabilitation therapy may have some risks. I understand that I
                        have
                        the right to ask about these risks and have any questions about my conditions answered prior to
                        treatment. I understand that at any time, except retrospectively, I may revoke this consent. I
                        acknowledge that
                    </div>
                </div>
                <div className="client-registration-consent-right ts-col-md-12 ts-col-lg-6">
                    <div>
                        <div className={'client-registration-consent__back_navigation'} onClick={handleNavigateBack}>
                            <IconButtonComponent className={'client-registration-consent__back_navigation_icon'}>
                                <ImageConfig.NavigateBack/>
                            </IconButtonComponent>
                            <div className={"client-registration-consent__back_navigation_text"}>
                                Previous
                            </div>
                        </div>
                        <Formik
                            validationSchema={registrationConsentFormValidationSchema}
                            initialValues={registrationFormInitialValues}
                            validateOnChange={false}
                            validateOnBlur={true}
                            enableReinitialize={true}
                            validateOnMount={true}
                            onSubmit={onSubmit}
                        >
                            {({values, validateForm, touched, errors, isValid, isSubmitting, setFieldValue}) => {
                                // eslint-disable-next-line react-hooks/rules-of-hooks
                                useEffect(() => {
                                    validateForm();
                                }, [validateForm, values]);
                                return (
                                    <Form className="t-form" noValidate={true}>
                                        <FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>
                                        <div className="t-form-controls">
                                            <div className={'registration-form-header'}>
                                                <FormControlLabelComponent className="registration-form-title"
                                                                           label={"Almost there!"}
                                                                           size={"lg"}
                                                />
                                                <div className="registration-form-sub-title">
                                                    By signing this consent, I acknowledge that I have carefully read
                                                    this Certification and Consent.
                                                </div>
                                            </div>
                                            <div>
                                                <div className="ts-row">
                                                    <div className="ts-col-md-12 ts-col-lg-6">
                                                        <Field name={'name'} className="t-form-control">
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        id={"name_input"}
                                                                        label={'Name'}
                                                                        placeholder={'Enter your name'}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                        titleCase={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-md-12 ts-col-lg-6">
                                                        <Field name={'date'} className="t-form-control">
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikDatePickerComponent
                                                                        id={"dob_input"}
                                                                        label={'Date'}
                                                                        placeholder={'Enter Date'}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                        disabled={true}
                                                                        clearable={false}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="ts-row">
                                                    <div className="ts-col-12">
                                                        <Field name={'is_acknowledged'} className="t-form-control">
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikCheckBoxComponent
                                                                        label={'I acknowledge that I have read and agree to the statement as written above'}
                                                                        required={true}
                                                                        formikField={field}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="ts-row">
                                                    <div className="ts-col-12">
                                                        <Field name={'is_authorized_representative'}
                                                               className="t-form-control">
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikCheckBoxComponent
                                                                        label={'I am an Authorized Representative for the client (ie. Parent, Legal Guardian, Agent, Manager, etc.)'}
                                                                        formikField={field}
                                                                        onChange={() => {
                                                                            setFieldValue('representative_name', '');
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                {
                                                    values.is_authorized_representative && <div className="ts-row">
                                                        <div className="ts-col-12">
                                                            <Field name={'representative_name'} className="t-form-control">
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Authorized Representative Name'}
                                                                            placeholder={'Enter Representative Name'}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                            titleCase={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="ts-row">
                                                    <div className="ts-col-12">
                                                        <SignaturePadComponent onSign={(signImage) => {
                                                            setFieldValue('signature', signImage);
                                                        }}/>
                                                    </div>
                                                    {
                                                        (_.get(touched, "signature") && !!(_.get(errors, "signature"))) &&
                                                        <ErrorComponent
                                                            errorText={(_.get(errors, "signature"))}/>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="t-form-actions">
                                            <ButtonComponent
                                                id={"submit_btn"}
                                                type={"submit"}
                                                prefixIcon={<ImageConfig.ArrowRightCircle/>}
                                                fullWidth={true}
                                                disabled={!isValid || isSubmitting}
                                                isLoading={isSubmitting}
                                            >
                                                Submit
                                            </ButtonComponent>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default ClientRegistrationConsentScreen;
