import "./ClientRegistrationMedicalHistorySurgicalHistoryComponent.scss";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import _ from "lodash";
import FormikTextAreaComponent from "../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent";
import {useSelector} from "react-redux";
import ButtonComponent from "../../shared/components/button/ButtonComponent";
import CheckBoxComponent from "../../shared/components/form-controls/check-box/CheckBoxComponent";
import FormikCheckBoxComponent from "../../shared/components/form-controls/formik-check-box/FormikCheckBoxComponent";
import {IRootReducerState} from "../../store/reducers";
import {ISurgicalHistoryOption} from "../../shared/models/common.model";
import React, {useCallback, useEffect, useState} from "react";
import {CommonService} from "../../shared/services";
import {IAPIResponseType} from "../../shared/models/api.model";
import {ImageConfig, Misc} from "../../constants";
import SearchComponent from "../../shared/components/search/SearchComponent";

interface ClientRegistrationMedicalHistorySurgicalHistoryComponentProps {
    onNext: () => void;
}


const ClientSurgicalHistoryValidationSchema = Yup.object({
    surgical_history: Yup.object({
        isCustomOption: Yup.boolean().nullable(),
        questions: Yup.array().nullable().when("isCustomOption", {
            is: false,
            then: Yup.array().min(1, 'Surgical history is required'),
            otherwise: Yup.array().nullable()
        }),
        comments: Yup.string().when("isCustomOption", {
            is: true,
            then: Yup.string().required('Comments is required'),
            otherwise: Yup.string().nullable()
        })
    }),
});

const ClientSurgicalHistoryInitialValues: any = {
    surgical_history: {
        questions: [],
        isCustomOption: false,
        comments: ""
    }
};

const ClientRegistrationMedicalHistorySurgicalHistoryComponent = (props: ClientRegistrationMedicalHistorySurgicalHistoryComponentProps) => {

    const {onNext} = props;
    const [searchKey, setSearchKey] = useState<string>("");
    const {surgicalHistoryOptionsList} = useSelector((state: IRootReducerState) => state.staticData);
    const [clientSurgicalHistoryInitialValues, setClientSurgicalHistoryInitialValues] = useState<any>(_.cloneDeep(ClientSurgicalHistoryInitialValues));

    const {
        clientMedicalHistoryDetails,
    } = useSelector((state: IRootReducerState) => state.client);

    useEffect(() => {
        if (clientMedicalHistoryDetails) {
            if (clientMedicalHistoryDetails?.surgical_history?.comments) {
                clientMedicalHistoryDetails.surgical_history.isCustomOption = true;
            }
            setClientSurgicalHistoryInitialValues({
                surgical_history: clientMedicalHistoryDetails.surgical_history || []
            });
        }
    }, [clientMedicalHistoryDetails]);

    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        const payload = {...CommonService.removeKeysFromJSON(_.cloneDeep(values), ['questions_details'])};
        setSubmitting(true);
        CommonService._client.ClientMedicalHistorySurgicalHistoryDetailsSaveAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setSubmitting(false);
                setClientSurgicalHistoryInitialValues(_.cloneDeep(values));
                onNext();
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error, true);
                setSubmitting(false);
            })
    }, [onNext]);

    const handleSurgicalHistoryOptionSelection = useCallback((optionId: string, selectedOptions: string[]) => {
        const options = _.cloneDeep(selectedOptions);
        const index = options?.findIndex((value: string) => value === optionId);
        if (index > -1) {
            options.splice(index, 1);
        } else {
            options.push(optionId);
        }
        return options;
    }, []);

    return (
        <div className={'client-registration-medical-history-surgical-history-component'}>
            <Formik
                validationSchema={ClientSurgicalHistoryValidationSchema}
                initialValues={clientSurgicalHistoryInitialValues}
                onSubmit={onSubmit}
                validateOnChange={false}
                validateOnBlur={true}
                enableReinitialize={true}
                validateOnMount={true}>
                {({values, isSubmitting, setFieldTouched, setFieldValue, setFieldError, isValid, validateForm}) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        console.log(values);
                        validateForm();
                    }, [validateForm, values]);
                    return (
                        <Form noValidate={true} className={"t-form"}>
                            <div className="t-form-controls">
                                <div className="ts-row">
                                    <div className="ts-col-lg-6">
                                        <SearchComponent value={searchKey}
                                                         onChange={value => setSearchKey(value)}/>
                                    </div>
                                </div>
                                <div className="ts-row">
                                    {
                                        surgicalHistoryOptionsList?.filter(item => item?.title?.toLowerCase()?.includes(searchKey?.trim()?.toLowerCase()))?.map((option: ISurgicalHistoryOption) => {
                                            return <div className="ts-col-lg-6" key={option._id}>
                                                <Field
                                                    name={"surgical_history.questions"}>
                                                    {(field: FieldProps) => (
                                                        <CheckBoxComponent
                                                            id={'cb_' + option?.title}
                                                            label={option?.title}
                                                            checked={field.field?.value?.indexOf(option._id) > -1}
                                                            onChange={() => {
                                                                setFieldTouched(field.field?.name);
                                                                setFieldValue(field.field?.name, handleSurgicalHistoryOptionSelection(option._id, field.field?.value));
                                                                validateForm();
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        })
                                    }
                                </div>
                                <div className="ts-row">
                                    <div className="ts-col-lg-6">
                                        <Field
                                            name={"surgical_history.isCustomOption"}>
                                            {(field: FieldProps) => (
                                                <FormikCheckBoxComponent
                                                    id={"other_cb"}
                                                    formikField={field}
                                                    label={"Other Surgery not Listed?"}
                                                    onChange={(isChecked) => {
                                                        if (!isChecked) {
                                                            setFieldValue('surgical_history.comments', "");
                                                            setFieldError('surgical_history.comments', undefined);
                                                            setTimeout(() => { // TODO solve fool proof
                                                                validateForm();
                                                            }, 10);
                                                        }
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                </div>
                                {values.surgical_history?.isCustomOption && <div className="ts-row">
                                    <div className="ts-col-12">
                                        <Field name={`surgical_history.comments`}>
                                            {
                                                (field: FieldProps) => (
                                                    <FormikTextAreaComponent
                                                        id={"comments"}
                                                        label={"Comments"}
                                                        placeholder={"Enter your comments here"}
                                                        disabled={!values.surgical_history?.isCustomOption}
                                                        required={values.surgical_history?.isCustomOption}
                                                        formikField={field}
                                                        fullWidth={true}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                </div>}
                            </div>
                            <div className="t-form-actions">
                                <ButtonComponent
                                    id={"next_btn"}
                                    type={"submit"}
                                    isLoading={isSubmitting}
                                    disabled={isSubmitting || !isValid}
                                    suffixIcon={<ImageConfig.ArrowRightCircle/>}
                                >
                                    Next
                                </ButtonComponent>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );

};

export default ClientRegistrationMedicalHistorySurgicalHistoryComponent;
