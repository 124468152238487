import "./TimeLineComponent.scss";
import {ITimelineStep} from "../../models/common.model";
import React, {useCallback} from "react";
import {ImageConfig} from "../../../constants";

interface TimeLineComponentProps {
    steps: ITimelineStep[];
    onStepClick?: (step: ITimelineStep) => void;
}


const TimeLineComponent = (props: TimeLineComponentProps) => {

    const {onStepClick, steps} = props;

    const handleStepClick = useCallback((step: ITimelineStep) => {
        if (onStepClick) {
            onStepClick(step);
        }
    }, [onStepClick]);

    return (
        <div className="time-line-component">
            {steps.map((step, index) => (
                <div key={step.key}
                     className={`timeline-block ${step.status}`}>
                    <div className={"timeline-line"}/>
                    <div className={"timeline-circle"}>
                        {
                            step.status === 'completed' && <ImageConfig.TimeLineStepCompletedIcon/>
                        }
                        {
                            step.status === 'in-progress' && <ImageConfig.TimeLineStepInProgressIcon/>
                        }
                        {/*<span className="timeline-count">{index + 1}</span>*/}
                    </div>
                    <div className={"timeline-content"} onClick={() => handleStepClick(step)}>
                        <div className="timeline-title">
                            {step.title}
                        </div>
                        {step.description && <div className="timeline-description">
                            {step.description}
                        </div>}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TimeLineComponent;

// ****************************** USAGE STARTS ****************************** //
//
// const TimeLineSteps: ITimelineStep[] = [
//     {
//         key: '1',
//         title: 'Step 1',
//         description: 'Step 1 description',
//         status: 'completed'
//     },
//     {
//         key: '2',
//         title: 'Step 2',
//         status: 'completed'
//     },
//     {
//         key: '3',
//         title: 'Step 3',
//         status: 'completed'
//     },
//     {
//         key: '4',
//         title: 'Step 4',
//         status: 'in-progress'
//     },
//     {
//         key: '5',
//         title: 'Step 5',
//         status: 'pending'
//     }
// ]
//     <TimeLineComponent steps={TimeLineSteps}/>
//
// ****************************** USAGE ENDS ****************************** //
